import React, { useEffect, Fragment, useMemo, useState,useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import ReplyTwoToneIcon from '@material-ui/icons/ReplyTwoTone';
import { useHistory } from 'react-router-dom';
import DeleteIcon from "@material-ui/icons/Delete";

import CalendarTodayIcon from "@material-ui/icons/CalendarToday";


// import LogoutIcon from '@material-ui/icons/PowerSettingsNew';

import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import "./index.css"

import PrintIcon from '@material-ui/icons/Print';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import DateFnsUtils from '@date-io/date-fns';
import ReactLoading from 'react-loading';

import Slide from "@material-ui/core/Slide";

import SearchIcon from '@material-ui/icons/Search';

import Select from '@material-ui/core/Select';

import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";

import GridContainer from "../components/Grid/GridContainer.js";
import GridItem from "../components/Grid/GridItem.js";

import TextField from '@material-ui/core/TextField';

import FeedingTable from './FeedingTable.js'

import Autocomplete from '@material-ui/lab/Autocomplete';

import { forwardRef } from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import DialogActions from "@material-ui/core/DialogActions";

import PictureAsPdfSharpIcon from '@material-ui/icons/PictureAsPdfSharp';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import SaveIcon from '@material-ui/icons/Save';
import MaterialTable from 'material-table'
import { BorderBottom, BorderLeft, BorderRight, Flag, PowerSettingsNew } from '@material-ui/icons';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { View } from '@react-pdf/renderer';
import RefreshIcon from '@material-ui/icons/Refresh';




const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
function formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
}

function addZero(i) {
    if (i < 10) {
        i = "0" + i;
    }
    return i;
}
function to24Hours(d) {
    var h = addZero(d.getHours());
    var m = addZero(d.getMinutes());
    var s = addZero(d.getSeconds());
    return h + ":" + m + ":" + s;
}
const useStyles = makeStyles({
  root: {
    // fontSize: '0.5em', // Adjust this value to change the font size
    width: '100%',
  },
  inputRoot: {
    // fontSize: '0.5em', // Adjust this value to change the font size in the input
  },
  option: {
    fontSize: '0.7em', // Adjust this value to change the font size of options
    maxHeight: 1000,
  },
});

function msToTime(seconds) {

    var minutes = parseInt(seconds / 60, 10);
    seconds = seconds % 60;
    var hours = parseInt(minutes / 60, 10);
    minutes = minutes % 60;

    return addZero(hours) + ':' + addZero(minutes);
}

export default function Lab_Tests() {
    const classes = useStyles();
    var date = new Date()
    var temp_role = localStorage.getItem('role')
    date.setDate(date.getDate() - 10);

    const [from, setFrom] = React.useState(date)
    const [to, setTo] = React.useState(new Date())
    const [width, height] = [window.innerWidth, window.outerWidth];
    
    const [loading, setLoading] = React.useState(true)
    const history = useHistory();


    
  const [pdfScanFlag,setpdfScanFlag]=React.useState(false)
    
    const [branches, setBranches] = React.useState([])
    const [branch, setBranch] = React.useState("")
    const [selectBranch, setSelectBranch] = React.useState([])
    // 9-jan-2023
    // for departments
    const [departments,setDepartments] = React.useState([])
    const [selectedDepartments,setSelectedDepartments] = React.useState()



//for CallingUpTestsList_api 
const [feedResultInvoices,setfeedResultInvoices] = React.useState([
  
])
const [CallingUpTestsList,setCallingUpTestsList]=React.useState()

//for Test Title jo invoice select hui us se jo related test obj hn wo es me daly hn
const [testList,settestList] = React.useState([])
const [selectedTest, setselectedTest] = React.useState()
// jo test parameter next api se us k lye
const [testParametersFull,settestParametersFull] = React.useState([])
const [testParameter, settestParameter] = React.useState()



const [Patientid, setPatientid] = React.useState("")
// const [PatientMobile, setPatientMobile] = React.useState()
// used for search and Color change of selected invoice in Invoices  table  1-feb-2024
const [Invoiceid, setInvoiceid] = React.useState("")
// used for search and Color change of selected invoice in Invoices  table 
const [selectedTestAuxId, setselectedTestAuxId] = React.useState()
// for show name in material Table
const [selectedTestName, setselectedTestName] = React.useState("Tests Result Feeding ")
// for scan pdf
const [ScanPDF, setScanPDF] = React.useState('')
const [PDFDetails, setPDFDetails] = React.useState('')


// for scan image 
const [ScanImage, setScanImage] = React.useState('')
const [ImageDetails, setImageDetails] = React.useState('')

//for patient name
const [name, setName] = React.useState("");




//for remarks
const [remarksInternal,setRemarksInternal]=React.useState("")
// for comments 
const [comments, setComments] = React.useState([])
// for open comments dialog box
const [openCommentDialodBox,setopenCommentDialodBox]=React.useState(false)
// for all comments coming from API
const [allComments,setallComments]=React.useState()
//for if_updated
// const [ifUpdated, setIfUpdated] = React.useState(false)

// for if_auto_LIMS
const [ifAutoLims, setifAutoLims] = React.useState()
//for Rejection remarks
const [rejectionRemarks,setRejectionRemarks]=React.useState("")
//for age calculator of patient because in other way every time function call
const [agecalculate,setAgecalculate]=React.useState("")
// 18-sep-2024
const [openSecondPageBox,setopenSecondPageBox]=React.useState(false)
// 24-sep-2024 
const [prevResultCheckBox,setprevResultCheckBox]=React.useState(false)
// 22-nov-2024 
const [loadingNew,setloadingNew]=React.useState(false)
// 28-nov-2024
const [lookup, setLookup] = React.useState({});
// 20-dec-2024
const [openRejectToPhelDialodBox,setopenRejectToPhelDialodBox]=React.useState(false)
// 23-DEC-2024 
const [selectedRowDetails,setselectedRowDetails]=useState()
// 30-dec-2024
const [openDocumentViewDialodBox,setopenDocumentViewDialodBox]=React.useState(false)
// 31-dec-2024 for documentView 
const [selectedReport,setSelectedReport]=React.useState('https://reports.mpl-labs.pk:8443/4DACTION/GetReportPDF/286951')
{/* 29-jan-2025 */}
const [ifLateCheckBox,setifLateCheckBox]=React.useState(false)
const [CallingUpTestsListForSearchIfLate,setCallingUpTestsListForSearchIfLate]=React.useState()
// 20-feb-2025 
const [ifExtraParamCheckBox,setifExtraParamCheckBox]=React.useState(false)
// 3-mar-2025
const [loadinResultSave,setloadingResultSave]=useState(false)
const [loadinResultSaveUpdate,setloadinResultSaveUpdate]=useState(false)


// for pdf
var pdfurl="data:application/pdf;base64,"
  const [selectInvoice,setselectInvoice]=React.useState(false)
  const [rows, setRows] = React.useState([]);

  //for scanned image
  var tesTmg= "data:image/png;base64,";
  const [selectImage,setselectImage]=React.useState(false)
  const [rowsImg, setRowsImg] = React.useState([]);
 // for those documents whos cannot be open in normal situation 23-jan-2025 
 const [selectedReportNotShowing,setSelectedReportNotShowing]=React.useState('')
 const [selectedReportNotShowingCondition,setSelectedReportNotShowingCondition]=React.useState(false)
//  for histo 24-feb-2025
const [formData, setFormData] = useState({
  histoNumber: "",
  specimenType: "",
  clinicalInfo: "",
  grossExam: "",
  microscopicExam: "",
  opinions: "",
});
const [submittedData, setSubmittedData] = useState([]); // Store submitted data

const handleChange = (field) => (event) => {
  setFormData({ ...formData, [field]: event.target.value });
};
const fields = [
  { label: "Histo #", key: "histoNumber" },
  { label: "Specimen Type", key: "specimenType" },
  { label: "Clinical Information", key: "clinicalInfo" },
  { label: "Gross Examination", key: "grossExam" },
  { label: "Microscopic Examination", key: "microscopicExam" },
  { label: "Opinions", key: "opinions" },
];
const handleSubmit = () => {
  // Check if all fields are filled
  if (Object.values(formData).every((value) => value.trim() !== "")) {
    setSubmittedData([...submittedData, formData]); // Add data to the array
    setFormData({  // Reset form fields
      histoNumber: "",
      specimenType: "",
      clinicalInfo: "",
      grossExam: "",
      microscopicExam: "",
      opinions: "",
    });
    alert("Data submitted successfully!");
  } else {
    alert("Please fill in all fields before submitting.");
  }
};
// histo end
 

    useEffect(() => {
      // 20-feb-2025 by default select all branch
      if(localStorage.getItem('branch_id')==1000 || !localStorage.getItem('branch_id')){
 setBranch("All")
      localStorage.setItem('branch_id', 1000)
      localStorage.setItem('branch_name', "1- MPL, All")
      }
     


      // 
        if (localStorage.getItem('org_branch_id') == null || localStorage.getItem('org_branch_id') == undefined) {
            localStorage.clear()
            window.location.href = "https://feedsign.mpl-labs.pk/login"
        }
        if (localStorage.getItem('user_id') == null || localStorage.getItem('user_id') == undefined) {
            localStorage.clear()
            window.location.href = "https://feedsign.mpl-labs.pk"
        }
        fetch("https://reports.mpl-labs.pk:8443/4DACTION/BranchesLoadAllfromWebserver").then(res => res.json()).then((response) => {
            setBranches(response)

        })
        // for built in comments 
        fetch("https://reports.mpl-labs.pk:8443/4DACTION/WebCommentsWritingCallsUpWS_api").then(res => res.json()).then((result) => {
          // setBranches(response)
          // console.log("WebCommentsWritingCallsUpWS_api")
          // console.log(result)
          // console.log("WebDepartmentsCallingUpWS_api")
          const resultconver=JSON.parse(result.arr_comments_code)
                          // console.log(result)
                          var AllComments=[]
                          resultconver.map((item,index)=>{
                            var temp={
                              comment_code:item,
                              comment_text:JSON.parse(result.arr_comments_text)[index],
                                  select: <Button
                              variant="contained"
                              color="secondary"
                              style={{
                                height: '50%',
                                backgroundColor:"#196c92"
                              }}
                              onClick={()=>{
                                // // alert("hello")
                                // var temp=JSON.parse(result.arr_comments_text)[index]+""
                                // // console.log(comments)
                                // // console.log("new")
                                // // console.log(temp)

                                // // setComments([...comments,temp])
                                // setComments(comments => [...comments, temp]);
                                // 9-jan-2025 format issue aa rha tha

                                try {
                                  const parsedComments = JSON.parse(result.arr_comments_text); // Parse once
                                  const newComment = parsedComments[index]?.toString(); // Safely access index
                                  
                                  if (newComment) {
                                    // setComments((prevComments) => [...prevComments, newComment]); // Append to comments
                                    setComments((prevComments) => `${prevComments}\n${newComment}`);
                                  } else {
                                    console.error("Invalid comment at the specified index");
                                  }
                                } catch (error) {
                                  console.error("Error parsing comments:", error);
                                }
                                
      
                              }}
                              >Select</Button>
                            }
                            AllComments.push(temp)
                            // console.log(aikaor)
                          })
                          setallComments(AllComments)
                          // console.log(AllComments)

      })
        var patient_id = localStorage.getItem('patient_id')
        var role = localStorage.getItem('role')



        var branchID = localStorage.getItem('branch_id')

        var from = new Date();
        from.setDate(from.getDate() - 10);
        var to = new Date();

        var objj = {
          branch_id:parseInt(branchID)==1000?"":parseInt(branchID),
          user_id:localStorage.getItem('user_id'),
          date_start:from.toISOString(),
          date_end:to.toISOString(),
          patient_id:"",
          invoice_id:"",
          array_dep_id:[]

        }
        // console.log("obj is "+JSON.stringify(objj))
fetch("https://reports.mpl-labs.pk:8443/4DACTION/RF_CallingUpTestsList_api", {
            method: "POST",
            body: JSON.stringify(objj)
        }).then((res) => res.json()).then((response) => {
            console.log("Check RF_CallingUpTestList_api API"+JSON.stringify(response))
            // 1-feb-2024 1
            // setCallingUpTestsList(response)

         var   obj={}
         var objnew=[]
            response.map((i,index)=>{
            // 1-feb-2024 1

                // const matchingPersons = objnew?.filter(data => data.invoice_id == i.invoice_id);
// if (matchingPersons.length > 0) { 
//   console.log(`is in the array. Matching objects:`, matchingPersons +"and "+JSON.stringify(i.invoice_id));
// } else {
//   console.log(` is not in the array.`);
//   objnew.push({invoice_id:i.invoice_id,
//     serial_no:i.serial_no,

//   })
// }
  // console.log(` is not in the array.`);
  objnew.push({
...i,
bookDateTime:new Date(i.booking_date).ddmmyyy()+","+msToTime(i.booking_time)+"hrs",
repotDateTime:new Date(i.reporting_date).ddmmyyy()+","+msToTime(i.reporting_time)+"hrs"

// select: (
//   <Button
//     variant="contained"
//     color="primary"
//     style={{
//       backgroundColor:"#196c91",color:"white"

//     }}
//     className={classes.button}
//     onClick={() => {
//       var objj = {
          
//         aux_id:i.aux_id,
//         invoice_id:i.invoice_id,
//         test_id:i.text_id

//       }
//       console.log("obj is "+JSON.stringify(objj))
// fetch("https://reports.mpl-labs.pk:8443/4DACTION/RF_CallingUpTestsParameters_api", {
//           method: "POST",
//           body: JSON.stringify(objj)
//       }).then((res) => res.json()).then((response) => {
//           console.log("Check RF_CallingUpTestParameter_api"+JSON.stringify(response))
//           settestParametersFull(response)
//           settestParameter(response.parameters_obj)
//           setInvoiceid(i.invoice_id)
//           setselectedTestAuxId(i.aux_id)
//           setselectedTestName(i.test_title)

         
//       })
     
//     }}
//   >
//     Select
//   </Button>
// ),
  })
            })
            // console.log("check obj value"+JSON.stringify(objnew))
                     // 1-feb-2024 1
         // setfeedResultInvoices(objnew)
            setCallingUpTestsList(objnew)
            // 29-jan-2025
            setCallingUpTestsListForSearchIfLate(objnew)


           
        })
   

            setLoading(false)


        // fetch("https://reports.mpl-labs.pk:8443/4DACTION/WebPortal_GetAllPanelsList").then((res) => res.json())
        //     .then((result) => {
        //         setpanelList(result)
        //     })
        // for departsmnet
        fetch("https://reports.mpl-labs.pk:8443/4DACTION/WebDepartmentsCallingUpWS_api").then((res) => res.json())
            .then((result) => {
                // setpanelList(result)
                // console.log("WebDepartmentsCallingUpWS_api")
const resultconver=JSON.parse(result.arr_dep_id)
                // console.log(result)
                var AllDepartments=[]
                resultconver.map((item,index)=>{
                  var temp={
                    dep_id:item,
                    dep_name:JSON.parse(result.arr_dep_title)[index]
                  }
                  AllDepartments.push(temp)
                  // console.log(aikaor)
                })
                // console.log(AllDepartments)
                setDepartments(AllDepartments)
            })
        if (localStorage.getItem('org_branch_id') == '0') {
            fetch("https://reports.mpl-labs.pk:8443/4DACTION/BranchesLoadAllfromWebserver").then(res => res.json()).then((response) => {
              // 27-jan-2025
               const allBranches = [{ 
AllowExceptionCredit
: 
false,
BranchID
:1000,
BranchName
: 
"1- MPL,All",
BranchNameDisplay
: 
"1- MPL,All",
If_Franchise
: 
false,
MobileNo
: 
"",
MobileService
: 
0,
Province
: 
"",
RestrictsInvoiceUnpaid
: 
false,
                }, ...response];
                console.log("Branches")
                console.log(allBranches)
              setBranches(allBranches)

                var obj = {}
                // 27-jan-2025
                obj[1000]="1- MPL, All"
                response.map((item) => {
                    obj[item.BranchID] = item.BranchName
                })
                console.log("obj")
                console.log(obj)
                setSelectBranch(obj)

                setBranch(localStorage.getItem('branch_id'))
            })
        }
setBranch("ALL")



    }, [])

     // 23-dec-2024 for image
 // Trigger file input click
const fileInputRef = useRef(null);


const handleButtonClick = () => {
 fileInputRef.current.click();
};
const handleFileChange = (event) => {
 const file = event.target.files[0];
 if (file) {
   // setFileName(file.name);
   console.log('Selected file:', file);
   const validTypes = ['image/jpeg', 'image/jpg', 'image/png'];
   if (!validTypes.includes(file.type)) {
     alert('Please upload file having extensions .jpeg/.jpg/.png only.');
     return
   }
   else{
setImageDetails(file)
   
   convertToBase64(file); 
   }
 }
};
// Convert file to Base64
const convertToBase64 = (file) => {
 const reader = new FileReader();
 reader.onload = () => {
   // setBase64(reader.result); // Set Base64 string
   console.log(reader.result)
   setScanImage(reader.result)
 };
 reader.readAsDataURL(file);
};
// for pdf 
const fileInputRefpdf = useRef(null);


const handleButtonClickpdf = () => {
 fileInputRefpdf.current.click();
};
const handleFileChangepdf = (event) => {
 const file = event.target.files[0];
 if (file) {
   if (file.type !== 'application/pdf') {
     alert('Invalid file type. Please select a PDF file.');
    
     return;
   }

   // setFileName(file.name); // Display file name
   console.log("pdf")
   setPDFDetails(file)
   convertToBase64pdf(file);  // Convert file to Base64
   // console.log()
 }
};
const convertToBase64pdf = (file) => {
 const reader = new FileReader();
 reader.onload = () => {
   // setBase64(reader.result); // Set Base64 string
        setScanPDF(reader.result)
   // console.log(reader.result)
   // setScanImage(reader.result)
 };
 reader.readAsDataURL(file);
};

    Date.prototype.hhmm = function () {
        var mm = this.getMinutes();
        var hh = this.getHours();

        return [
            (hh > 9 ? '' : '0') + hh,
            (mm > 9 ? '' : '0') + mm
        ].join(':');
    };

    Date.prototype.ddmmyyy = function () {
        var mm = this.getMonth() + 1; // getMonth() is zero-based
        var dd = this.getDate();

        return [
            (dd > 9 ? '' : '0') + dd,
            (mm > 9 ? '' : '0') + mm,
            this.getFullYear()
        ].join('/');
    };
    
    
      
      const columnsNew = [
        // { title: 'Name', field: 'name', editable: 'never' },

        { title: 'Test Title', field: 'parameter_title', editable: 'never' },
        // 21-nov-2024
        // { title: 'Result', field: '',},
        { title: 'Result', 
          field:"result_status",
          // field: 'category', 
          lookup: lookup
         },
        { title: 'Result', field: 'result_value', 
       
        render: (rowData) => {
          const actualValue =(rowData.result_value).replace(/,/g, '');
          console.log(actualValue)
          const actualValuewithoutComma =parseFloat(actualValue)
          console.log(actualValuewithoutComma)

          const minValue = parseFloat(rowData.range_min);
          const maxValue = parseFloat(rowData.range_max);
          console.log(minValue)
          console.log(maxValue)

          if(isNaN(actualValue))
  
          if (isNaN(actualValue) || isNaN(minValue) || isNaN(maxValue)) {
            return (
              <div style={{  color:  'black' ,fontWeight: 'bold' }}>
                {/*25-dec-2024 (1/2) ye line add ki hai string kr dya for symbol entry*/}
                {rowData.result_value}
              </div>
            );
          }
  
          const style = {
            color: (actualValuewithoutComma >= minValue && actualValuewithoutComma <= maxValue) ? 'black' : 'red',
            // backgroundColor: actualValue >= minValue && actualValue <= maxValue ? 'white' : 'pink',
            fontWeight: 'bold',
            // fontSize:12
          };
  
          return <div style={style}>{rowData.result_value}</div>;
        },
  
       },
        { title: 'Ref Range', field: 'ref_range', editable: 'never' },
        { title: 'Unit', field: 'test_units', editable: 'never' },
        { title: 'Previous 1', field: 'previous_result_1',editable: 'never'  },
        { title: 'Previous 2', field: 'previous_result_2',editable: 'never'  },
    ];
    const columns = [
      { title: 'S/Nn', field: 'serial_no', editable: 'never', 
        cellStyle: { minWidth: 5, maxWidth: 5 },
        headerStyle: { minWidth: 5, maxWidth: 5 },
      },
      { title: 'Invoice_Id', field: 'invoice_id',
        cellStyle: { minWidth: 50, maxWidth: 50 },
        headerStyle: { minWidth: 50, maxWidth: 50 },
       },
      { title: 'MR_No', field: 'mr_no', editable: 'never',
        cellStyle: { minWidth: 50, maxWidth: 50 },
        headerStyle: { minWidth: 50, maxWidth: 50 },
       },
      { title: 'P_Name', field: 'patient_name', editable: 'never',
        cellStyle: { minWidth: 150, maxWidth: 150 },
        headerStyle: { minWidth: 150, maxWidth: 150 },
       },
      { title: 'P_Age', field: 'age', editable: 'never',
        cellStyle: { minWidth: 200, maxWidth: 200 },
        headerStyle: { minWidth: 200, maxWidth: 200 },
       },
      { title: 'P_Gender', field: 'gender', editable: 'never',
       
       },
      { title: 'Book_D/T', field: 'bookDateTime', editable: 'never',
         cellStyle: { minWidth: 170, maxWidth: 170 },
      headerStyle: { minWidth: 170, maxWidth: 170 }, },
      { title: 'Test_Title', field: 'test_title', editable: 'never',
        cellStyle: { minWidth: 250, maxWidth: 250 },
        headerStyle: { minWidth: 250, maxWidth: 250 },
       },
      { title: 'Report_D/T', field: 'reporting_DateTime', editable: 'never',
        cellStyle: { minWidth: 150, maxWidth: 150 },
        headerStyle: { minWidth: 150, maxWidth: 150 },
       },
      { title: 'Department', field: 'department', editable: 'never', 
        cellStyle: { minWidth: 200, maxWidth: 200 },
      headerStyle: { minWidth: 200, maxWidth: 200 }, },
      { title: 'Machine', field: 'machine', editable: 'never' ,
        cellStyle: { minWidth: 150, maxWidth: 150 },
        headerStyle: { minWidth: 150, maxWidth: 150 },
      },
      { title: 'Ref_No', field: 'ref_no', editable: 'never',
     
       },
      { title: 'Ref_By', field: 'ref_by', editable: 'never',
        cellStyle: { minWidth: 230, maxWidth: 200 },
        headerStyle: { minWidth: 230, maxWidth: 200 },
       },
      { title: 'Panel', field: 'panel_name', editable: 'never',
        cellStyle: { minWidth: 250, maxWidth: 250 },
        headerStyle: { minWidth: 250, maxWidth: 250 },
       },
      { title: 'Branch', field: 'branch_name', editable: 'never',
        cellStyle: { minWidth: 200, maxWidth: 200 },
        headerStyle: { minWidth: 200, maxWidth: 200 },
       },
      // { title: 'Department', field: 'invoice_id', editable: 'never' },
      { title: 'Mobile_No', field: 'mobile', editable: 'never' },

  ];
    
    const fakecolumns = [
      { title: 'ID', field: 'id', editable: 'never' }, // 'never' means it's not editable
      { title: 'Name', field: 'name' },
      { title: 'Age', field: 'age' },
    ];
      // for pdf
      // const handleScanPDF = async (event) => {
      //   const file = event.target.files[0]
      //   console.log(file)
      //   var fileInput = document.getElementById('ScanPDF');
      //   var filePath = fileInput.value;
      //   var allowedExtensions = /(\.pdf)$/i;
    
      //   if (!allowedExtensions.exec(filePath)) {
      //     alert('Please upload file having extensions .pdf only.');
      //     fileInput.value = '';
      //     setScanPDF('')
      //     return false;
      //   } else {
      //     const base64 = await convertBase64(file)
      //     console.log("after convert into base64"+base64)
      //     // console.log("after convert into base64"+base64.split('base64,')[1])
    
      //     setScanPDF(base64)
      //   }
    
      // }
      const handleScanImage = async (event) => {
        // console.log("Invoice HandlerScanImage 417 :",event)
        const file = event.target.files[0]
        var fileInput = document.getElementById('ScanImage');
        var filePath = fileInput.value;
        var allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
        if (!allowedExtensions.exec(filePath)) {
          alert('Please upload file having extensions .jpeg/.jpg/.png only.');
          fileInput.value = '';
          setScanImage('')
          return false;
        } else {
          // console.log("Invoice HandlerScanImage 428 before convert into base :",file)
          const base64 = await convertBase64(file)
          // console.log("Invoice HandlerScanImage 430 after convert into base :",base64)
          setScanImage(base64)
        }
    
      }
      const handleScanPDF = async (event) => {
        const file = event.target.files[0]
        // console.log(file)
        var fileInput = document.getElementById('ScanPDF');
        var filePath = fileInput.value;
        var allowedExtensions = /(\.pdf)$/i;
    
        if (!allowedExtensions.exec(filePath)) {
          alert('Please upload file having extensions .pdf only.');
          fileInput.value = '';
          setScanPDF('')
          return false;
        } else {
          const base64 = await convertBase64(file)
          // console.log("after convert into base64"+base64)
          // console.log("after convert into base64"+base64.split('base64,')[1])
    
          setScanPDF(base64)
        }
    
      }
      const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(file)
          fileReader.onload = () => {
            resolve(fileReader.result);
          }
          fileReader.onerror = (error) => {
            reject(error);
          }
        })
      }
      const DocumentViewApiCallingFunction = () => {
        var obj22={
          doc_type:"PDF",
          doc_tag_or_title:"",
          patient_id:selectedRowDetails?.mr_no,
          // date_start :from.toISOString(),
          date_start :"",
          // date_end:to.toISOString()
          date_end:""

              }
              console.log("obj22 is"+JSON.stringify(obj22))
            fetch("https://reports.mpl-labs.pk:8443/4DACTION/WebDMS_ListsCallingUpFromServer",
            {
                   method:"POST",
            'Content-Type':"application/json",
            body: JSON.stringify(obj22)
            }
            ).then((res) => res.json()).then((response) => {
                console.log("WebDMS_ListsCallingUpFromServer PDF"+JSON.stringify(response))
        var the_rows = []
                
               
                response.map((data) => {
                  console.log("data is "+JSON.stringify(data))
                  var obj = {
                    dms_id:data.dms_id,
                    dms_tag:data.dms_tag,
                    dms_title:data.dms_title,
                    dms_date:(new Date(data.dms_date).getDate()) + "/" + (new Date(data.dms_date).getMonth() + 1) + "/" + (new Date(data.dms_date).getFullYear()),
                    // dms_date:new Date(data.dms_date).toISOString().replace(/T.*/,'').split('-').reverse().join('-'),
                    view: 
                    <Button variant="contained" color="secondary"
                    style={{ backgroundColor: '#196c91', color: 'white' }} 
                    //  key={data.invoice_no}
                    disableElevation
                     onClick={() => {       
                         var obj22={
                        dms_id:data.dms_id,
                        doc_type:"PDF",
                            }
                            console.log("obj22 is"+JSON.stringify(obj22))
                          fetch("https://reports.mpl-labs.pk:8443/4DACTION/WebDMS_ImageOrPDF_BLOBCallingUp",
                          {
                                 method:"POST",
                          'Content-Type':"application/json",
                          body: JSON.stringify(obj22)
                          }
                          ).then((res) => res.json()).then((response) => {
                              console.log("WebDMS_ImageOrPDF_BLOBCallingUp"+JSON.stringify(response)) 
                              // picture_image
                              // setSelectedReport(`${tesTmg}`+response.picture_image)
                              setSelectedReportNotShowingCondition(false)
                              setSelectedReportNotShowing(response.dms_id)
                              //for pdf
                              setSelectedReport(`${pdfurl}`+response.text_base64)
                              console.log("image base64"+response.picture_image)
                              setTimeout(() => {
                                setselectInvoice(true)
                              }, 500);
                          }) 
                                }}>
                                  <  PictureAsPdfSharpIcon />
                                </Button>
                  }
                  the_rows.push(obj)      
                              })
                              setRows(the_rows.reverse())
              
              })

              var obj22={
                doc_type:"SCAN",
                doc_tag_or_title:"",
                patient_id:selectedRowDetails?.mr_no,
                // date_start :from.toISOString(),
                date_start :"",
                // date_end:to.toISOString()
                date_end:""
      
                    }
                    console.log("obj22 is"+JSON.stringify(obj22))
                  fetch("https://reports.mpl-labs.pk:8443/4DACTION/WebDMS_ListsCallingUpFromServer",
                  {
                         method:"POST",
                  'Content-Type':"application/json",
                  body: JSON.stringify(obj22)
                  }
                  ).then((res) => res.json()).then((response) => {
                      console.log("WebDMS_ListsCallingUpFromServer iMAGE"+JSON.stringify(response))
                      // setallDosumentsPDF(response)
                      var the_rows = []
    
                // for pdf use this one 
                // window.open('https://reports.mpl-labs.pk:8443/4DACTION/GetReportPDF/' +response[3].dms_id)  
                response.map((data) => {
    console.log("data is "+JSON.stringify(data))
    var obj = {
      dms_id:data.dms_id,
      dms_tag:data.dms_tag,
      dms_title:data.dms_title,
      dms_date:(new Date(data.dms_date).getDate()) + "/" + (new Date(data.dms_date).getMonth() + 1) + "/" + (new Date(data.dms_date).getFullYear()),
      // dms_date:new Date(data.dms_date).toISOString().replace(/T.*/,'').split('-').reverse().join('-'),
      view: 
      <Button variant="contained" 
      style={{ backgroundColor: '#196c91', color: 'white' }} 
      //  key={data.invoice_no}
      disableElevation
       onClick={() => {       
           var obj22={
          dms_id:data.dms_id,
          doc_type:"SCAN",
              }
              console.log("obj22 is"+JSON.stringify(obj22))
            fetch("https://reports.mpl-labs.pk:8443/4DACTION/WebDMS_ImageOrPDF_BLOBCallingUp",
            {
                   method:"POST",
            'Content-Type':"application/json",
            body: JSON.stringify(obj22)
            }
            ).then((res) => res.json()).then((response) => {
                // console.log("WebDMS_ImageOrPDF_BLOBCallingUp"+JSON.stringify(response)) 
                // picture_image
                setSelectedReport(`${tesTmg}`+response.text_base64)
                //for pdf
                // setSelectedReport(`${pdfurl}`+response.text_base64)
                console.log("image base64"+`${tesTmg}`+response.text_base64)
                setTimeout(() => {
                  setselectImage(true)
                }, 500);
            }) 
                  }}>
                    < PhotoLibraryIcon />
                  </Button>
    }
    the_rows.push(obj)      
                })
            setRowsImg(the_rows.reverse())
                    
                    })

      }




    return (
        <>
            <Paper className={classes.root} style={{}}>
                {/* <GridContainer style={{ paddingBottom: '2em',paddingLeft: '2em',paddingRight: '2em', }}> */}
                <GridContainer  style={{ paddingRight: '1em',marginTop:"" ,}}>
                    
                
                    <GridItem xs={12} sm={11} md={11} lg={11} style={{marginBottom:"1%",marginTop:"0.5em",display:"flex",}}  >
                    {localStorage.getItem('org_branch_id') == '0'?
                        <FormControl variant="filled" className={classes.formControl} style={{ 
                          // width: "10em",
                          width: "9em",

                           marginLeft:0,}} >
                            <InputLabel id="demo-simple-select-filled-label">Branch</InputLabel>
                            <Select
                                labelId="demo-simple-select-filled-label"
                                id="demo-simple-select-filled"
                                value={branch}
                                onChange={(e) => {
                                    var result = window.confirm('Are you Sure ?. Your want to change Branch?')
                                    if (result) {
                                        setBranch(e.target.value)
                                        localStorage.setItem('branch_id', e.target.value)
                                        localStorage.setItem('branch_name', selectBranch[e.target.value])
                                        window.location.reload()
                                    }

                                }}
                            >
                                <MenuItem value={"0"} disabled>Select</MenuItem>
                                {
                                    branches?.map((item) => {
                                        return <MenuItem value={item.BranchID} key={item.BranchID}>{item.BranchName.split('MPL,')[1]}</MenuItem>

                                    })
                                }
                            </Select>
                        </FormControl>:<>
                        <FormControl variant="filled" className={classes.formControl} style={{ 
                          // width: "10em",
                          width: "9em",

                           marginLeft:0,}} >
                            {/* <InputLabel id="demo-simple-select-filled-label">Branch</InputLabel> */}
                            <Typography variant="body1" style={{ marginTop: "16px", paddingLeft: "8px" ,alignSelf:"center"}}>
   {localStorage.getItem('name')}
  </Typography>
                        </FormControl></>}
                          <MuiPickersUtilsProvider utils={DateFnsUtils} >
                    <KeyboardDatePicker style={{ 
                      marginLeft:20,
                      // width:"12em"
                   width:"9em",
                    }}
                        variant="inline"
                        inputVariant="outlined"
                        label="From"
                        format="dd/MM/yyyy"
                        value={from}
                        InputAdornmentProps={{ position: "start" }}
                        onChange={date => setFrom(date)}
                        // new
                        InputProps={{
                          style: { fontSize: 14,
                            paddingTop:"0.1em",paddingBottom:"0.1em"
                           }, // Adjust font size here
                      }}
                      KeyboardButtonProps={{
                        style: { paddingRight: 0 }, // Reduce right padding to decrease space
                    }}
                      keyboardIcon={<CalendarTodayIcon style={{ fontSize: 16, }} />} 
                    
                    />
                </MuiPickersUtilsProvider>
                <MuiPickersUtilsProvider utils={DateFnsUtils} >
                    <KeyboardDatePicker
                    style={{ marginLeft: 20,
                      // width:"12em",
                   width:"9em",
                    }}
                        variant="inline"
                        inputVariant="outlined"
                        label="To"
                        format="dd/MM/yyyy"
                        value={to}
                        InputAdornmentProps={{ position: "start" }}
                        onChange={date => setTo(date)}
                        // new
                        InputProps={{
                          style: { fontSize: 14,
                            paddingTop:"0.1em",paddingBottom:"0.1em"
                           }, // Adjust font size here
                      }}
                      KeyboardButtonProps={{
                        style: { paddingRight: 0 }, // Reduce right padding to decrease space
                    }}
                      keyboardIcon={<CalendarTodayIcon style={{ fontSize: 16, }} />} 
                    />
                </MuiPickersUtilsProvider>
                <Autocomplete
          id="Ref_By_Doctor"

          options={departments}
          classes={{
            // option: classes.option
            option: classes.option,
            root: classes.root,
            inputRoot: classes.inputRoot,
          }}
          autoHighlight
          getOptionLabel={(option) => option.dep_name + ""}
          style={{
            // width: "10em",
            width: "9em",
            
            marginLeft: 20,
      
          }}
          // renderOption={(option) => <React.Fragment>{option.DoctorName}</React.Fragment>}
          onChange={(event, newValue) => {
        //  console.log(newValue)
         if(newValue){
         setSelectedDepartments(newValue.dep_id)
         }else{
          setSelectedDepartments(null)
         }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Departments"
              variant="outlined"
              // style={{
              //   // width: "30em"
              // }}
              style={{
                width: "9em",
                
                // marginLeft: 20,
              }}
              inputProps={{
                ...params.inputProps,
                autoComplete: "new-password" // disable autocomplete and autofill
              }}
            />
          )}
        />
    <TextField
      id="outlined-required"
      label="Patient ID"
      value={Patientid}
      onChange={(e) => {
        setPatientid(e.target.value);
      }}
      style={{
        // width: "10em",
        width: "9em",

        
        marginLeft: 20,
      }}
      variant="outlined"
    />

    <TextField
      id="outlined-required"
      label="Invoice ID"
      value={Invoiceid}
      onChange={(e) => {
        setInvoiceid(e.target.value);
      }}
      style={{
        // width: "10em",
        width: "9em",

        marginLeft: 20,
      }}
      variant="outlined"
    />
                          <Button variant="contained"  style={{backgroundColor:"#196c91",color:"white", width: "8em",maxHeight:"3.8em",
        
        marginLeft: 20,}} disabled={loading}
onClick={() => {
// alert("DATE from"+from+"DATE to "+to+"invoice id"+Invoiceid+"patient iD"+Patientid)
 //  SeveRecordHandler()
 if(localStorage.getItem('branch_name')){
var branchID = localStorage.getItem('branch_id')

 var obj = {
  // branch_id:parseInt(branchID),
  branch_id:parseInt(branchID)==1000?"":parseInt(branchID),
  user_id:localStorage.getItem('user_id'),
  date_start:from.toISOString(),
  date_end:to.toISOString(),
  patient_id:Patientid,
  invoice_id:Invoiceid,
  array_dep_id:selectedDepartments?[selectedDepartments]:[]

}
//  console.log("checj obj "+JSON.stringify(obj))
 fetch("https://reports.mpl-labs.pk:8443/4DACTION/RF_CallingUpTestsList_api", {
    method: "POST",
    body: JSON.stringify(obj)
}).then((res) => res.json()).then((response) => {
    // console.log("Check RF_CallingUpTestList_api API"+JSON.stringify(response))
    // 1-feb-2024 1
    // setCallingUpTestsList(response)

 var   obj={}
 var objnew=[]
    response.map((i,index)=>{
    // 1-feb-2024 1

        // const matchingPersons = objnew?.filter(data => data.invoice_id == i.invoice_id);
// if (matchingPersons.length > 0) { 
//   console.log(`is in the array. Matching objects:`, matchingPersons +"and "+JSON.stringify(i.invoice_id));
// } else {
//   console.log(` is not in the array.`);
//   objnew.push({invoice_id:i.invoice_id,
//     serial_no:i.serial_no,

//   })
// }
// console.log(` is not in the array.`);
objnew.push({
  
...i,
bookDateTime:new Date(i.booking_date).ddmmyyy()+","+msToTime(i.booking_time)+"hrs",
repotDateTime:new Date(i.reporting_date).ddmmyyy()+","+msToTime(i.reporting_time)+"hrs"

})
    })

    setCallingUpTestsList(objnew)      
     // 29-jan-2025
     setCallingUpTestsListForSearchIfLate(objnew)     
})
 }
 else{
  alert("Please Select Branch !")
 }
}}                  >
                    Search<SearchIcon />
                </Button>
                <View  style={{width:"10em"}}>
               { localStorage.getItem("signing")=="true"?
                <Button  style={{backgroundColor:"#196c91",color:"white", width: "9em",maxWidth: "9em",minWidth:"9em",maxHeight:"2em",fontSize:12,
        
        marginLeft: 20,}} disabled={loading}
onClick={() => {
  history.push('/ResultSigningWindow');
}}                  >
                    Result Sign
                    {/* <SearchIcon /> */}
                </Button>:<></>}
                <Button  style={{backgroundColor:"#196c91",color:"white", width: "9em",maxWidth: "9em",minWidth:"9em",maxHeight:"2em",fontSize:12,marginTop:"1%",
        
        marginLeft: 20,}} disabled={loading}
onClick={() => {
  window.location.reload()

}}                  >
                    Refresh
                    {/* <SearchIcon /> */}
                </Button>
          
                </View>
                      {/* 29-jan-2025 */}
                      <View style={{width:"10em",marginTop:"-0.5em"}} >
                      <FormControlLabel
              control={<Checkbox  checked={ifLateCheckBox} 
              onChange={(event)=>{
                console.log("cheked ......")
                console.log(event.target.checked)
                setifLateCheckBox(event.target.checked)
               if(event.target.checked){
                  const finalizedTests = CallingUpTestsListForSearchIfLate.filter((test) =>    
                     test.if_late==true 
                );
                setCallingUpTestsList(finalizedTests)   
                console.log(finalizedTests)
              }else{
console.log("me chala")
setCallingUpTestsList(CallingUpTestsListForSearchIfLate)   

              }
                 
                
              }} name="if late" />}
              // label="Prev Result"
              label={ <Typography style={{ fontSize: 11,fontWeight:"bold" }}>
             Late Result
            </Typography>
            }
            />
                       <FormControlLabel
                       style={{marginTop:-16}}
              control={<Checkbox  checked={ifExtraParamCheckBox} 
              onChange={(event)=>{
                console.log("cheked ......")
                console.log(event.target.checked)
                setifExtraParamCheckBox(event.target.checked)
//                if(event.target.checked){
//                   const finalizedTests = CallingUpTestsListForSearchIfLate.filter((test) =>    
//                      test.if_late==true 
//                 );
//                 setCallingUpTestsList(finalizedTests)   
//                 console.log(finalizedTests)
//               }else{
// console.log("me chala")
// setCallingUpTestsList(CallingUpTestsListForSearchIfLate)   

//               }
                 
                
              }} name="if late" />}
              // label="Prev Result"
              label={ <Typography style={{ fontSize: 11,fontWeight:"bold" }}>
             Optional Parameter
            </Typography>
            }
            />
              </View>
                           
                    </GridItem>
                    
            
            {/* <GridItem xs={6} sm={6} md={2} lg={2} style={{marginTop:"0.5em"}}>

                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        variant="inline"
                        inputVariant="outlined"
                        label="From"
                        format="dd/MM/yyyy"
                        value={from}
                        InputAdornmentProps={{ position: "start" }}
                        onChange={date => setFrom(date)}
                    />
                </MuiPickersUtilsProvider>
            </GridItem> */}
            {/* {
                width < 600
                    ?
                    <GridItem xs={6} sm={6} md={0} lg={0}>

                    </GridItem> : ""
            } */}

            {/* <GridItem xs={6} sm={6} md={2} lg={2} style={{marginTop:"0.5em"}}>

                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        variant="inline"
                        inputVariant="outlined"
                        label="To"
                        format="dd/MM/yyyy"
                        value={to}
                        InputAdornmentProps={{ position: "start" }}
                        onChange={date => setTo(date)}
                    />
                </MuiPickersUtilsProvider>

            </GridItem> */}
            {/* {
                width < 600
                    ?
                    <GridItem xs={6} sm={6} md={0} lg={0}>

                    </GridItem> : ""
            }
             */}
    {/* <GridItem xs={6} sm={6} md={2} lg={2} style={{}}>
        

    
    </GridItem> */}
    {/* <GridItem xs={6} sm={6} md={2} lg={2} style={{}}>

    </GridItem> */}
    {/* <GridItem xs={12} sm={12} md={12} lg={6} style={{display:"flex",marginTop:"0.5em",}}>
   
            
         
    </GridItem> */}
           
            
            <GridItem xs={12} sm={1} md={1} lg={1} style={{ margin: 'auto',display:"flex",justifyContent:"start",alignItems:"start" }} >

            {/* <div  style={{fontSize:12,justifyContent:"start",alignItems:"start",display:"flex",flexDirection:"column"}}>
            <p style={{marginTop:-10,fontWeight:"bold",color:"#196c92"}}><span style={{fontWeight:"bold",color:"black"}}>Ref_By_Doctor :</span> {testParametersFull.doctor_name}</p>
             
                          
              
                <p style={{marginTop:-10,fontWeight:"bold",color:"#196c92"}}><span style={{fontWeight:"bold",color:"black"}}>Reg Date & Time :</span>{testParametersFull!=""? new Date(testParametersFull.invoice_date).ddmmyyy()+","+msToTime(testParametersFull.invoice_time):""}</p>

                </div> */}
                <div  style={{fontSize:15,justifyContent:"center",alignItems:"center",display:"flex",flexDirection:"column",marginLeft:2}}>
                {/* <p>Patient_name :{testParametersFull.patient_name}</p> */}
                {/* <p style={{marginTop:-10}}>Ref_By_Doctor:{testParametersFull.panel_name}</p> */}
                <p style={{fontWeight:"bold",color:"#196c92",position:"absolute"}}><span style={{color:"black"}}>User </span><span className='blink'>{localStorage.getItem('name')}</span></p>


                </div>
                < PowerSettingsNew onClick={()=>{
                  // history.push('/ResultSigningWindow');
                  // 
                  //  deleteAllCookies()
                  var result = window.confirm('Are you Sure ?. Your want to Logout ?')
                  if (result) {
                    localStorage.clear()
                    // window.location.href="https://invoicing.mpl-labs.pk/login"
                      window.location.reload()
                  }
                   
                  // alert("hy")
                }} style={{cursor:"pointer",position:"absolute",right:10,top:-10}}></PowerSettingsNew>

            </GridItem>
            {/* <GridItem xs={12} sm={12} md={12} lg={12} style={{ margin: 'auto',display:"flex",justifyContent:"start",alignItems:"start", }} >

<div  style={{fontSize:12,justifyContent:"start",alignItems:"start",display:"flex",flexDirection:"row"}}>
<p style={{fontWeight:"bold",color:"#196c92"}}><span style={{color:"black"}}>User :</span><span className='blink'>{localStorage.getItem('name')}</span></p>

{testParameter?<>
   <p  style={{fontWeight:"bold",color:"#196c92"}}><span style={{color:"black",marginLeft:14}}>Mr_No :</span>{testParametersFull.mr_number}</p>
   <p style={{fontWeight:"bold",color:"#196c92"}}><span style={{color:"black",marginLeft:14}}>Patient_name :</span>{testParametersFull.patient_name}</p>

           
    <p style={{fontWeight:"bold",color:"#196c92"}}><span style={{color:"black",marginLeft:14}}>Gender :</span>{testParametersFull.gender}</p>

    <p style={{fontWeight:"bold",color:"#196c92"}}><span style={{color:"black",marginLeft:14}}>Age :</span>{agecalculate}</p>
    <p style={{fontWeight:"bold",color:"#196c92"}}><span style={{color:"black",marginLeft:14}}>Gender :</span>{testParametersFull.gender}</p>
    <p style={{fontWeight:"bold",color:"#196c92"}}><span style={{color:"black",marginLeft:14}}>Mobile :</span>{testParametersFull.mobile_no}</p>

    </>:<></>}
    </div>

   

</GridItem> */}
            </GridContainer>


                {loading && localStorage.getItem('branch_id') != '0' ?
                    <center>
                        <ReactLoading type={'spinningBubbles'} color={'black'} height={'10em'} width={'10em'} />
                    </center>
                    :
                    <>
                        {/* <GridContainer> */}
                        <GridContainer style={{ 
                          
                          // paddingLeft: '1em',paddingRight: '1em',marginTop:"", 
                          }}>
                            {/* <GridItem md={3}>
                                {
                                    Object.keys(testRecord).length>1 ?
<FeedingTable testRecord={testRecord} />
: null
                                }
                                
                            </GridItem> */}
                             {/* <GridItem md={4}>
                             <Autocomplete
                          
                          disablePortal
                          id="combo-box-demo"
                          options={["110022","12122","12112"]}
                          sx={{ width: 300 }}
                          style={{marginLeft:14}}
                          renderInput={(params) => <TextField {...params} label="Invoice Selection" />}
                          value={""}
                          onChange={(event, newInputValue) => {


                          }}
                          
                          />
                          </GridItem> */}
                          {/* <GridItem md={8}></GridItem> */}
                          {/* For Histo Departments  aREA */}

                          {/* <GridItem md={4} lg={4} style={{ paddingLeft: '2em',paddingTop: '2em',paddingBottom: '2em' }}> */}
                          <GridItem md={12} lg={12} style={{
                            // height: "400px", overflow: "auto",
                            // borderBottom:"1px solid #E4E4E4", 
                            // border:"10px solid #E4E4E4" ,
                            // overflow: "auto", 
                            // height: "1000px",
                             }}>
       <MaterialTable
      //  style={{marginLeft:-12}}
      style={{border:"2px solid #196c91"}}
      title={<p style={{ fontSize:16,fontWeight:"bold" }}>Test Feeding List</p>}
      columns={columns}
      data={CallingUpTestsList}
      options={{
        // paging: false,
        // search: false,
        // fixedColumns: { left: 1 },
        headerStyle: {
          backgroundColor:"#196c91",
          color: "#FFF",
          fontSize: 12,
          // position: "sticky", // Make the header sticky
      // top: 0, // Stick the header to the top
          // textAlign: "center",
          // fontWeight: "bold",
          // minHeight:"50em"
        },
        // maxBodyHeight: "1000px",
        maxBodyHeight: "1000px",
        pageSize: 100,
        fixedHeader: true,
        actionsColumnIndex: -1,
        //  tableLayout: "fixed",
      //    rowStyle: {
      //     overflowWrap: 'break-word'
      // },
   
      rowStyle: (rowData, index) => {
        // console.log(rowData)
        const currentInvoiceID = rowData?.invoice_id;
        const nextInvoiceID = index < CallingUpTestsList.length - 1 ? CallingUpTestsList[index + 1]?.invoice_id: null;
        const previousInvoiceID = index > 0 ? CallingUpTestsList[index - 1]?.invoice_id: null;
  
        // Debugging
        // console.log(`Row: ${index}, Current: ${currentInvoiceID}, Next: ${nextInvoiceID}, Previous: ${previousInvoiceID}`);
  
        // Apply background color based on conditions
        const backgroundColor =rowData.if_late?"#ff4d4d":
          currentInvoiceID === nextInvoiceID || currentInvoiceID === previousInvoiceID
            // ? '#d1e1e9' // Set color if the current ID matches next or previous
            ? '#FFFFFF' // Set color if the current ID matches next or previous

            : index % 2 === 0
            ? '#FFFFFF' // Default color for even rows
            : '#EEEEEE'; // Alternate color for odd rows
  
        return {
          backgroundColor,
          fontSize: 12,
          color:rowData.if_late?"white":"black"
        };
      }
      }}
      onRowClick={(event, rowData, togglePanel) => {
        // setOpenRow(openRow === rowData.id ? null : rowData.id);
        if(localStorage.getItem('branch_name')){
         
          setopenSecondPageBox(true)
          // setloadingNew(true)
        // togglePanel();
        console.log("data is "+JSON.stringify(rowData))
        
      var j=0

        var object = {   
          // aux_id:594980,
          // invoice_id:"0502208",
          // test_id:"UR-001-T-0409" 
          aux_id:rowData.aux_id,
          invoice_id:rowData.invoice_id,
          test_id:rowData.text_id,
          IfOptionalAlso:ifExtraParamCheckBox
        }
        console.log("RF_CallingUpTestsParameters_api object is "+JSON.stringify(object))
       fetch("https://reports.mpl-labs.pk:8443/4DACTION/RF_CallingUpTestsParameters_api", {
            method: "POST",
            body: JSON.stringify(object)
        }).then((res) => res.json()).then((response) => {
            console.log("Check RF_CallingUpTestParameter_api"+JSON.stringify(response))
            // yhan se comment kiya 
  //           const parentId = j;
  //           var obj={
  //             id:j,
  //             name:rowData.test_title
  //           }
  //           console.log("obj is "+JSON.stringify(obj))
            
  
  // // Add parentId property to each object in the array
  // const arrayWithParentId = response.parameters_obj.map((obj,index)=> (
     
  //   {
  //     ...obj,
  //     parentId: parentId,
  //     id:100
     
  // }


  // ));
  // arrayWithParentId.push(obj)
  // console.log("Check 2nd API "+JSON.stringify(arrayWithParentId))
  // settestParameter(arrayWithParentId)
  
  //           // var parentId=i
  //           // var parId=response.parameters_obj+x
            
  //           // var holdParameterObject={...response.parameters_obj,parentId}
  //           // grandObj.push(holdParameterObject)
  //           // console.log("Check RF_CallingUpTestParameter_api 2nd"+JSON.stringify(holdParameterObject))
  
  //           // grandObj.push({
  //           //   id:i,
  //           //   test:response.parameters_obj
  //           // })
  //           // settestParametersFull(response)
  //           // settestParameter(response.parameters_obj)
  //           setInvoiceid(rowData.invoice_id)
  //           setselectedTestAuxId(rowData.aux_id)
  //           setselectedTestName(rowData.test_title) 
  

  // yhan tak
  // 23-dec-2024 more details show on 2nd page thats why pori row es me save krwa di 
  setselectedRowDetails(rowData)
  // 

                        settestParametersFull(response)
            settestParameter(response.parameters_obj)
            // setInvoiceid(rowData.invoice_id)
            setselectedTestAuxId(rowData.aux_id)
            setselectedTestName(rowData.test_title)
            // setIfUpdated(false)
            setifAutoLims(response.if_feeding_lims) 
            setName(response.patient_name)
            setComments(response.remarks_tests)
            setprevResultCheckBox( response.if_include_prev_results)
            // 1-jun-20
            // setAgecalculate(calculateAge(response.age_days))
            // setloadingNew(false)

              // Parse the array from the API response
      const posNegArray = JSON.parse(response.pos_neg_arr);

      // Create a lookup object dynamically
      const dynamicLookup = posNegArray.reduce((acc, item, index) => {
        acc[item] = item; // Use index + 1 as the key
        return acc;
      }, {});

      setLookup(dynamicLookup);
        }).catch((err)=>{
alert(err)
setopenSecondPageBox(false)

        })
      }
      else{
        alert("Please Select Branch !")
        // setloadingNew(false)

      }
      }}
    
    />
</GridItem>

              
   
                           
                        
                            

                        </GridContainer>

                    </>
                }
                


            </Paper>
            {/* 2nd page open for test feeding */}
            <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal
        }}
        sx={{ '& .MuiDialog-paper': { width: '100vw', maxWidth: '100vw', margin: 0 } }}
        maxWidth={false}
        fullWidth={true}
        open={openSecondPageBox}
        TransitionComponent={Transition}
        keepMounted
        PaperProps={{
          style: {
            width: '99.8vw',        // Full viewport width
            maxWidth: '100vw',     // No max width
            height: '99,8vh',       // Full viewport height
            maxHeight: '100vh',  
            margin: 0              // Remove margin to ensure full width
          }
        }}
        onClose={() => {
      setopenSecondPageBox(false)
        }}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
   
        <DialogContent
          id="classic-modal-slide-description"
          // className={classes.modalBody}
        >
               <GridContainer  style={{marginTop:"" }}>
       <GridItem md={12} lg={12} style={{borderBottom:"1px solid #E4E4E4",marginTop:-25 }}>
        <div style={{display:"flex",alignItems:"center",flexDirection:"row",
          // justifyContent:"center"
          }}>
    <p style={{fontWeight:"bold",color:"#196c92",fontSize:14}}><span style={{color:"black",marginLeft:14}}>Branch : </span> {selectedRowDetails?.branch_name.replace(/^1-\s*/, '')}</p>

       <p  style={{fontWeight:"bold",color:"#196c92",fontSize:14}}><span style={{color:"black",marginLeft:14}}>Mr_No : </span>{selectedRowDetails?.mr_no},</p>
       <p  style={{fontWeight:"bold",color:"#f2105b",fontSize:16}}><span style={{color:"black",marginLeft:14,}}>Invoice_Id : </span>{selectedRowDetails?.invoice_id},</p>

   <p style={{fontWeight:"bold",color:"#f2105b",fontSize:16}}><span style={{color:"black",marginLeft:14,}}>Patient_name : </span>{selectedRowDetails?.patient_name},</p>

           
    <p style={{fontWeight:"bold",color:"#f2105b",fontSize:16}}><span style={{color:"black",marginLeft:14,}}>Gender : </span>{selectedRowDetails?.gender},</p>

    <p style={{fontWeight:"bold",color:"#f2105b",fontSize:16}}><span style={{color:"black",marginLeft:14,}}>Age : </span>{selectedRowDetails?.age},</p>
    <p style={{fontWeight:"bold",color:"#196c92",fontSize:14}}><span style={{color:"black",marginLeft:14}}>Mobile : </span>{selectedRowDetails?.mobile},</p>
    
    </div>
             </GridItem>
             <GridItem md={12} lg={14} style={{borderBottom:"1px solid #E4E4E4",marginTop:-8 }}>
        <div style={{display:"flex",alignItems:"center",flexDirection:"row",
          // justifyContent:"center"
          }}>
      
    <p style={{fontWeight:"bold",color:"#196c92",fontSize:14}}><span style={{color:"black",marginLeft:14}}>Ref_By_Doctor : </span> {testParametersFull?.doctor_name},</p>
    <p style={{fontWeight:"bold",color:"#196c92",fontSize:14}}><span style={{color:"black",marginLeft:14}}>Ref_No : </span> {selectedRowDetails?.ref_no},</p>

             <p style={{fontWeight:"bold",color:"#196c92",fontSize:14}}><span style={{color:"black",marginLeft:14}}>Book_Date/Time : </span>{selectedRowDetails?.booking_date?new Date(selectedRowDetails?.booking_date).ddmmyyy()+","+msToTime(selectedRowDetails?.booking_time)+"hrs":""},</p>
             <p style={{fontWeight:"bold",color:"#196c92",fontSize:14}}><span style={{color:"black",marginLeft:14}}>Report_Date/Time : </span>{selectedRowDetails?.reporting_DateTime},</p>

    </div>
             </GridItem>
       <GridItem md={8} lg={10} style={{borderBottom:"1px solid #E4E4E4",  }}>
  
    {loadingNew?<>
      <ReactLoading style={{}}  type={'spinningBubbles'} color={'black'} height={15} width={15} />
      </>:
      <>
      {/* 24-feb-2025 histo reports */}
     
      {testParametersFull.layout_type=="ParaGraPH"?<>
    
    
      {/* <p>Me paragrh</p> */}
      <div 
      // style={{paddingBottom:"20%",paddingTop:"20%"}}
      >
     {fields.map(({ label, key }) => (
        <div key={key} style={{ display: "flex", flexDirection: "column", marginTop: "1.2%" }}>
          <b>{label}</b>
          <TextareaAutosize
            rowsMax={100}
            style={{ padding: "0.5em", height: "5em", width: "90%", marginLeft: 1 }}
            value={formData[key]}
            onChange={handleChange(key)}
            placeholder=""
          />
        </div>
      ))}


                  {/* <span style={{
    display: 'flex',
    flexDirection: "column",
    // width:"120%",
  
    marginTop: "1.2%",
   //  marginLeft:25
  }}>
    <b>Histo #</b>
    <TextareaAutosize
      rowsMax={100}
      style={{
        padding: '0.5em',
        height: '5em',
         width:"90%",
         
        marginLeft: 1
      }}
      value={comments}
      onChange={(e) => {
        setComments(e.target.value)
      }}
      placeholder=""
    />
    </span>
    <span style={{
    display: 'flex',
    flexDirection: "column",
    // width:"120%",
  
    marginTop: "1.2%",
   //  marginLeft:25
  }}>
    <b>Specimen Type</b>
    <TextareaAutosize
      rowsMax={100}
      style={{
        padding: '0.5em',
        height: '5em',
         width:"90%",
         
        marginLeft: 1
      }}
      value={comments}
      onChange={(e) => {
        setComments(e.target.value)
      }}
      placeholder=""
    />
    </span>
    <span style={{
    display: 'flex',
    flexDirection: "column",
    // width:"120%",
    marginTop: "1.2%",
  
   //  marginTop: 6,
   //  marginLeft:25
  }}>
    <b>Clinical Information</b>
    <TextareaAutosize
      rowsMax={100}
      style={{
        padding: '0.5em',
        height: '5em',
         width:"90%",
         
        marginLeft: 1
      }}
      value={comments}
      onChange={(e) => {
        setComments(e.target.value)
      }}
      placeholder=""
    />
    </span>
    <span style={{
    display: 'flex',
    flexDirection: "column",
    // width:"120%",
    marginTop: "1.2%",
  
   //  marginTop: 6,
   //  marginLeft:25
  }}>
    <b>Gross Examination</b>
    <TextareaAutosize
      rowsMax={100}
      style={{
        padding: '0.5em',
        height: '5em',
         width:"90%",
         
        marginLeft: 1
      }}
      value={comments}
      onChange={(e) => {
        setComments(e.target.value)
      }}
      placeholder=""
    />
    </span>
    <span style={{
    display: 'flex',
    flexDirection: "column",
    // width:"120%",
    marginTop: "1.2%",
  
   //  marginTop: 6,
   //  marginLeft:25
  }}>
    <b>Microscopic Examination</b>
    <TextareaAutosize
      rowsMax={100}
      style={{
        padding: '0.5em',
        height: '5em',
         width:"90%",
         
        marginLeft: 1
      }}
      value={comments}
      onChange={(e) => {
        setComments(e.target.value)
      }}
      placeholder=""
    />
    </span>
    <span style={{
    display: 'flex',
    flexDirection: "column",
    // width:"120%",
    marginTop: "1.2%",
  
   //  marginTop: 6,
   //  marginLeft:25
  }}>
    <b>Opinions</b>
    <TextareaAutosize
      rowsMax={100}
      style={{
        padding: '0.5em',
        height: '5em',
         width:"90%",
         
        marginLeft: 1
      }}
      value={comments}
      onChange={(e) => {
        setComments(e.target.value)
      }}
      placeholder=""
    />
    </span> */}

   
    </div>
      </>:
      
    <MaterialTable
   title={  <p style={{ fontSize: '18px' }}>{selectedTestName}</p>}
   
      columns={columnsNew}
      data={testParameter}
      // options={{actionsColumnIndex: -1  }}
      options={{
        selection: false,
        headerStyle: {
          backgroundColor:"#196c91",
          color: "#FFF",
          fontSize: 13,
          position: "sticky", // Make the header sticky
      top: 0, // Stick the header to the top
          // textAlign: "center",
          // fontWeight: "bold",
          // minHeight:"50em"
        },
        maxBodyHeight: "1000px",
        pageSize: 100,
        fixedHeader: true,
        actionsColumnIndex: -1,

        rowStyle: rowData => {
          
          return {
          fontSize: 13,
         
          backgroundColor:rowData.if_optional?"#e6e6e6":""
          
        };
        } ,

      }}
      
      actions={[
        {
            icon: () => (
             <>
                <DeleteIcon style={{ color: "#757575" }} /> 
                </>
            ),
          tooltip: "Delete Row",
          onClick: (event, rowData) => {
            if(rowData.if_optional){
            const updatedRows = testParameter.filter(row => row !== rowData);
            settestParameter(updatedRows);
            }else{
              alert("Only Delete Extra Parameter")
            }
          },
        },
      ]}

      editable={{
        isEditable: () => false, // Disable default row editing
        onBulkUpdate: (changes) =>
          new Promise((resolve, reject) => {
            const rows=Object.values(changes)
            // console.log("check edit ",rows)

            const updatedRows = [...testParameter];
            let index;
            rows.map (emp=>{
              // console.log(emp.oldData.tableData.id)
              index=emp.oldData.tableData.id
              updatedRows[index]=emp.newData
              // int float k ilawa b symbol value
                // 25-dec-2024 (2/2) ye line add ki hai string kr dya for symbol entry
              // updatedRows[index].result_value =parseFloat(emp.newData.result_value)  
              updatedRows[index].result_value =emp.newData.result_value          


            })
            settestParameter(updatedRows)
            // console.log(updatedRows)
          
            resolve();
          }),
      }}
    />
  }
  </>
  }
   </GridItem>
   <GridItem md={4} lg={2} style={{}}>
 
   
                  
                  <GridItem md={12} lg={12} style={{display:"flex",flexDirection:"row",
                  

                  }}>
<div style={{
  display:"flex",
  justifyContent:"flex-start",
 alignItems:"flex-start",
 marginLeft:-20
}}>

                  <span style={{
    display: 'flex',
    flexDirection: "column",
    width:"120%",
   //  marginTop: 6,
   //  marginLeft:25
  }}>
    <b>Remarks (Internal Use)</b>
    <TextareaAutosize
      rowsMax={4}
      style={{
        padding: '0.5em',
        height: '3em',
        // marginLeft: 1,
        width:"110%"
       
      }}
      // value={comments}
      value={remarksInternal}
      onChange={(e) => {
        setRemarksInternal(e.target.value)
      }}
      placeholder="Remarks for Internal use only "
    />
    </span>
    </div>
    </GridItem>
 
   
                
       <GridItem md={12} lg={12} style={{display:"flex",flexDirection:"row",}}>

   <div style={{
    display:"flex",
    justifyContent:"flex-start",
   alignItems:"flex-start",
   marginLeft:-20
   }}>
                     <span style={{
       display: 'flex',
       flexDirection: "column",
       width:"120%",
     
      //  marginTop: 6,
      //  marginLeft:25
     }}>
       <b>Comments</b>
       <TextareaAutosize
         rowsMax={10}
         style={{
           padding: '0.5em',
           height: '6em',
            width:"110%",
            
           marginLeft: 1
         }}
         value={comments}
         onChange={(e) => {
           setComments(e.target.value)
         }}
         placeholder="Comments to Print Ends of Reports"
       />
       </span>
       </div>
       </GridItem>
       <GridItem md={12} lg={12} style={{display:"flex",flexDirection:"row",marginTop:"3%"}}>

       <div style={{width:"100%",
   marginLeft:-20

       }}>
       <Button
           variant="contained"
           color="primary"
           onClick={() => {
      //  alert("hello")
            //  SeveRecordHandler()
            setopenCommentDialodBox(true)
            
           }}
   
           // disabled={saveInvoice}
           style={{
             textAlign: 'center',
            //  width: "1.5em",
            //  padding: "2.5em",
            width:"13em",
            height:"2.5em",
          fontSize:13,

            // paddingTop:"12%",paddingBottom:"12%",
            //  height: "3.8em",
            //  marginLeft: "5%",
            //  marginTop:"20%",
            //  marginBottom:10
            backgroundColor:"#196c91"
           }}>
                Comments
           </Button>
           </div>
       </GridItem>
       <GridItem md={12} lg={12} style={{display:"flex",flexDirection:"row",alignItems:"start",justifyContent:"start",marginTop:"3%"}}>

       <div style={{width:"100%",
   marginLeft:-20

       }}>
    <Button
            startIcon={ <PrintIcon fontSize={"small"} style={{color:"white",
           
            }} />}
        variant="contained"
        color="primary"
        onClick={() => {
   //  alert("hello")
         //  SeveRecordHandler()
        //  setopenCommentDialodBox(true)
        alert("working on it")
         
        }}

        // disabled={saveInvoice}
        style={{
          textAlign: 'center',
          //  width: "1.5em",
          //  padding: "2.5em",
          width:"13em",
          height:"2.5em",
        fontSize:13,

          // paddingTop:"12%",paddingBottom:"12%",
          //  height: "3.8em",
          //  marginLeft: "5%",
          //  marginTop:"20%",
          //  marginBottom:10
          backgroundColor:"#196c91"
        }}>
          Report Preview
        </Button>
      </div>
    </GridItem>
    <GridItem md={12} lg={12} style={{display:"flex",flexDirection:"row",marginTop:"3%"}}>
<div style={{
   marginLeft:-20,
   width:"100%",
}}>
    <Button
           startIcon={ <AutorenewIcon fontSize={"large"} style={{color:"white",}} />}

        variant="contained"
        color="primary"
        onClick={() => {
          // alert("hello")
          setopenRejectToPhelDialodBox(true)
   //  alert("hello")
         //  SeveRecordHandler()
        //  setopenCommentDialodBox(true)
//         console.log( selectedTestAuxId,
//           rejectionRemarks,
//          localStorage.getItem('user_id'),
//           localStorage.getItem('name'),
//         )
//    //  setopenSecondPageBox(false)
//    var obj={
//     aux_id:selectedTestAuxId,
//     remarks_rejection:rejectionRemarks,
//     user_id:localStorage.getItem('user_id'),
//     user_name:localStorage.getItem('name')
//    }
//    fetch("https://reports.mpl-labs.pk:8443/4DACTION/RF_TestRejectsToPhlebotomy_api", {
//     method: "POST",
//     body: JSON.stringify(obj)
// }).then((res) => res.json()).then((response) => {
//   console.log(response)
//   // if(response.transaction_status=="Successful"){
//     // ye abi add kiya box bnd ho jae
//  setopenSecondPageBox(false)

//     alert("Rejection Successfully done ! "+JSON.stringify(response))
//     window.location.reload();
    



// //   }
// })

         
        }}

        // disabled={saveInvoice}
        style={{  
          textAlign: 'center',
          //  width: "1.5em",
          //  padding: "2.5em",
          width:"13em",
          height:"2.65em",
        fontSize:13,

          // paddingTop:"12%",paddingBottom:"12%",
          //  height: "3.8em",
          //  marginLeft: "5%",
          //  marginTop:"20%",
          //  marginBottom:10
          backgroundColor:"#196c91"
                        }}
                  
                      simple
        >
          Reject to phlebotomy
        </Button>
        </div>
        </GridItem>
       <GridItem md={12} lg={12} style={{display:"flex",flexDirection:"row",marginTop:"3%"}}>
       {/* <Button
            variant="contained"
            color="primary"
            className={classes.button}
            style={{backgroundColor:"#196c91",width:150,fontSize:10}}
            >
 <FormControlLabel
 style={{fontSize:10}}
              control={<Checkbox checked={pdfScanFlag} onChange={(event)=>{
                setpdfScanFlag(event.target.checked)
              }} name="Scan PDF" />}
              label={ <Typography style={{ fontSize: 12,fontWeight:"bold" }}>
              Scan PDF
            </Typography>}
            />
            
          
            </Button>
            {
            !pdfScanFlag
            ?
            <div style={{}}>
            <Button
            variant="contained"
            color="primary"
            className={classes.button}
            style={{paddingTop:"2.5%",
              paddingBottom:"2.5%",marginLeft:"2.5%",
              backgroundColor:"#196c91"
            }}
            

          >
            <p style={{ fontSize: 12,fontWeight:"bold" }}>Attach PDF</p>
            <input type="file" variant="contained"
              // color="primary"
              style={{width:"60%"}}
              onChange={(e) => { handleScanPDF(e) }}
              id="ScanPDF"
               name="pdf" accept="application/pdf"
              className={classes.button}
              aria-label="Attach PDF"
              // hidden // This hides the native input element


            ></input>
          </Button>
          </div>
          :<></>
}
{
           pdfScanFlag ?
           <>
            <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            style={{paddingTop:"2%",paddingBottom:"2.5%",marginLeft:"2.5%",backgroundColor:"#196c91"}}

            onClick={async()=>{
              try{
                if(document.getElementById('response').innerHTML==""){
                  
                }else{
                  setScanPDF(document.getElementById('response').innerHTML)
                }
            }
            catch(e){
              // console.log(e)
              alert("Scan File First!!")
            }
            }}
          >
            <b>{ScanPDF=="" ? "Load Scan PDF File" : "Loaded SCAN PDF"}</b>
          
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            style={{
              paddingTop:"2%",paddingBottom:"2.5%",marginLeft:"2.5%",backgroundColor:"#196c91"}}

            onClick={async()=>{
              window.scanToLocalDisk()
            }}
          >
            <b>Scan PDF</b>
            <div id="response" hidden={true} ></div>
          </Button> 
       
       
   
          </>
          :
          null
         }
*/}
 <div style={{width:"100%",
   marginLeft:-20

       }}>
     <Button
       startIcon={ <PictureAsPdfSharpIcon fontSize={"small"} style={{color:"white",
        marginRight: "0.5em", // Add space to the right of the icon
       
       }} />}
          variant="contained"
          color="primary"
          style={{
            justifyContent: "flex-start", // Align content to the start
            textAlign: "left",           // Align text to the left
            width:"13em",
            height:"2.5em",
       
          fontSize:13,
            backgroundColor:"#196c91"
          }}
          onClick={handleButtonClickpdf}
        >
          {/*  */}
        {PDFDetails.name?PDFDetails.name:"Select pdf"}  

        </Button>
        <input
        type="file"
        accept="application/pdf" // Restrict to PDF files
        ref={fileInputRefpdf}
        onChange={handleFileChangepdf}
        style={{ display: 'none' }}
      />
   </div>
    </GridItem>
       <GridItem md={12} lg={12} style={{display:"flex",flexDirection:"row",marginTop:"3%"}}>
       {/* <div style={{width:"20%"}}> */}
       {/* 23-dec-2024 */}
     
                          {/* <Button
            variant="contained"
            color="primary"
            className={classes.button}
            style={{backgroundColor:"#196c91",}}
            >
 <FormControlLabel
              control={<Checkbox checked={prevResultCheckBox} onChange={(event)=>{
                setprevResultCheckBox(event.target.checked)
              }} name="Prev Result" />}
              // label="Prev Result"
              label={ <Typography style={{ fontSize: 12,fontWeight:"bold" }}>
             Prev Result
            </Typography>}
            />
            
          
            </Button> */}
            {/* </div> */}
        {/* 23-dec-2024 */}
       {/* <Button
            variant="contained"
            color="primary"
            className={classes.button}
            style={
              {
                // paddingTop:"4%",paddingBottom:"4%",backgroundColor:"#196c91",width:"80%"
                paddingTop:"2%",
              paddingBottom:"2%",
              marginLeft:"2.5%",
              backgroundColor:"#196c91"

              }}
          >
          
            <p style={{ fontSize: 12,fontWeight:"bold" }}> Image</p>
            <input type="file" variant="contained"
              color="primary"
              style={{width:"60%"}}
              
              onChange={(e) => { handleScanImage(e) }}
              id="ScanImage" name="img" accept="image/*"
              className={classes.button}

              aria-label="Attach PDF"
            ></input>
          </Button> */}
         <div style={{width:"100%",
   marginLeft:-20

       }}>
            <Button
              startIcon={ <PhotoLibraryIcon fontSize={"small"} style={{color:"white",
                marginRight: "0.3em", // Add space to the right of the icon
       
              }} />}
          variant="contained"
          color="primary"
          className={classes.button}
          style={{
            // paddingTop:"2%",paddingBottom:"2.5%",marginLeft:"2.5%",backgroundColor:"#196c91"
            justifyContent: "flex-start", // Align content to the start
            textAlign: "left",           // Align text to the left
            //  width: "1.5em",
            //  padding: "2.5em",
            width:"13em",
            height:"2.5em",
          fontSize:13,
  
            // paddingTop:"12%",paddingBottom:"12%",
            //  height: "3.8em",
            //  marginLeft: "5%",
            //  marginTop:"20%",
            //  marginBottom:10
            backgroundColor:"#196c91"
          }}
          onClick={handleButtonClick}
        >
        {ImageDetails.name?ImageDetails.name:"Select Image"}  
        </Button>
        <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileChange}
        style={{ display: 'none' }} // Hide the default file input
      />
         </div>
                 </GridItem>
                 <GridItem md={12} lg={12} style={{display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"space-between",marginTop:"3%"}}>
                 <div style={{width:"100%",
   marginLeft:-20

       }}>
                 <Button
                  startIcon={ <VisibilityIcon fontSize={"small"} style={{color:"white",
                    marginRight: "0.3em", // Add space to the right of the icon
       
                  }} />}
           variant="contained"
           color="primary"
           onClick={() => {
            
    //  alert("hello")
    DocumentViewApiCallingFunction()
    setopenDocumentViewDialodBox(true)
    
           }}
   
           // disabled={saveInvoice}
           style={{
            justifyContent: "flex-start", // Align content to the start
            textAlign: "left",           // Align text to the left
            //  width: "1.5em",
            //  padding: "2.5em",
            width:"13em",
            height:"2.5em",
          fontSize:13,
  
            // paddingTop:"12%",paddingBottom:"12%",
            //  height: "3.8em",
            //  marginLeft: "5%",
            //  marginTop:"20%",
            //  marginBottom:10
            backgroundColor:"#196c91"
            //  marginBottom:10
           }}>
           Documents
           </Button>
           </div>
           </GridItem>
           <GridItem md={12} lg={12} style={{display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"space-between",marginTop:"3%"}}>
                 <div style={{width:"100%",
   marginLeft:-20

       }}>
                 <Button
                  startIcon={ <VisibilityIcon fontSize={"small"} style={{color:"white",
                    marginRight: "0.3em", // Add space to the right of the icon
       
                  }} />}
           variant="contained"
           color="primary"
           onClick={() => {
            
    //  alert("hello")
    // DocumentViewApiCallingFunction()
    // setopenDocumentViewDialodBox(true)
    localStorage.setItem("forDocumentsPid",selectedRowDetails?.mr_no)
                window.open('https://feedsign.mpl-labs.pk/DocumentView')  
                // window.open('http://localhost:3000/DocumentView')  

           }}
   
           // disabled={saveInvoice}
           style={{
            justifyContent: "flex-start", // Align content to the start
            textAlign: "left",           // Align text to the left
            //  width: "1.5em",
            //  padding: "2.5em",
            width:"13em",
            height:"2.5em",
          fontSize:13,
  
            // paddingTop:"12%",paddingBottom:"12%",
            //  height: "3.8em",
            //  marginLeft: "5%",
            //  marginTop:"20%",
            //  marginBottom:10
            backgroundColor:"#196c91"
            //  marginBottom:10
           }}>
           Documents N
           </Button>
           </div>
           </GridItem>
                 <GridItem md={12} lg={12} style={{display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"space-between",marginTop:"3%"}}>
                 <div style={{width:"100%",
   marginLeft:-20

       }}>
                 <Button
                  startIcon={ <SaveIcon fontSize={"small"} style={{color:"white",
                    marginRight: "0.3em", // Add space to the right of the icon
       
                  }} />}
           variant="contained"
           color="primary"
           onClick={() => {
      //  alert("hello")
      // for branch selection 
      if(localStorage.getItem('branch_name')){
// 3-mar-2025
        setloadingResultSave(true)

var arrparameterid = []
var arrgrouptitle = []
var arrparametertitle = []
var arrapplygender = []
var arrapplyage = []
var arrresultvalue = []
var arrresultstatus = []
var arrtestunit = []
var arrtestrefrange = []
var arrtestparagraph = []
var arrprevious1 = []
var arrprevious2 = []
// 27-nov-2024 
var arrpostiveNegative = []
// 24-feb-2025 histo reports agr histo k ilawa ho to ye array fill ho jae wo wali na ho ku k wo agr param 2 hn to us me 6 he rhy ge thats why
var arrtemforHsistoResult=[]
      var obj ={}
   var data= testParameter?.map((item,index)=>{
console.log("value in testParameter is "+JSON.stringify(item))
arrparameterid.push(item.aux_id)
arrgrouptitle.push("")

arrparametertitle.push(item.parameter_title)
arrapplygender.push(item.apply_gender)
arrapplyage.push(item.apply_age)
arrresultvalue.push(item.result_value=="-"?"":item.result_value)
arrresultstatus.push(item.result_status)
arrtestunit.push(item.test_units)
arrtestrefrange.push(item.ref_range)
arrtestparagraph.push(item.test_paragraph)
arrprevious1.push(item.previous_result_1)
arrprevious2.push(item.previous_result_2)
// 27-nov-2024 

// 2-jan-2025 prev save value show h=ni ho rhi thi thats why cateogory ko skip kr in table column then change kr dya 
  // arrpostiveNegative.push(item.category?item.category:"")
arrpostiveNegative.push(item.result_status?item.result_status:"")
arrtemforHsistoResult.push("")
// 3-mar-2025 es ko cmment kiya ye crash krwa rha tha while saving data
// delete item.tableData
// return item
      })
      console.log({
       arrresultvalue:arrresultvalue,
       arrpostiveNegative:arrpostiveNegative
        
      })
      //28-jan-2025 empty result cannot b submitted
      const areAllResultValueEmpty = arrresultvalue.every(value => value === '');
      const areAllResultPosNegEmpty = arrpostiveNegative.every(value => value === '');
      // 24-feb-2025 histo reports condition k oper map kiya
      if(testParametersFull.layout_type!="ParaGraPH"){
      if(areAllResultValueEmpty && areAllResultPosNegEmpty ){
        alert("Result is empty & cannot be saved !")
                   window.location.reload();
return
      }}
      // 24-feb-2025 histo reports
      const valuesArray = Object.values(formData);
     if(testParametersFull.layout_type=="ParaGraPH"){
      if (Object.values(formData).every((value) => value.trim() !== "")) {
        // setSubmittedData([...submittedData, formData]); // Add data to the array
        setFormData({  // Reset form fields
          histoNumber: "",
          specimenType: "",
          clinicalInfo: "",
          grossExam: "",
          microscopicExam: "",
          opinions: "",
        });
        console.log(valuesArray)
        // alert("Data submitted successfully!");
      } else {
        alert("Please fill in all fields before submitting.");
        setloadingResultSave(false)
      }
    }
      // console.log("form data")
// console.log(formData)

      // 
      // console.log(arrparameterid)

      // console.log("value delete change"+JSON.stringify(testParameter))
      testParametersFull.parameters_obj=testParameter
      var obj = {
        aux_id:selectedTestAuxId,
        remarks_tests:remarksInternal,
        comments_tests:comments.toString(),
        if_updated:false,
        if_auto_LIMS:ifAutoLims,
        user_id:localStorage.getItem('user_id'),
        user_name:localStorage.getItem('name'),
        PDF_String: ScanPDF.includes("base64,") ? ScanPDF.split('base64,')[1] : ScanPDF,
        PDF_Name: ScanPDF != "" ? PDFDetails.name: "",
        // 23-dec-2024
        // PDF_Name: ScanPDF != "" ? name+".pdf" : "",

        Image_String:ScanImage != "" ? ScanImage.split('base64,')[1]:"",
        Image_Name: ScanImage != "" ? ImageDetails.name : "",
// 23-dec-2024
        // Image_Name: ScanImage != "" ? name + ".png" : "",
        arr_paramter_id:arrparameterid,
        arr_group_title:arrgrouptitle,
        arr_paramter_title:arrparametertitle,
        arr_apply_gender:arrapplygender,
        arr_apply_age:arrapplyage,
        arr_result_value:arrresultvalue,
        // arr_result_status:arrresultstatus,
        arr_result_status:arrpostiveNegative,
        arr_test_unit:arrtestunit,
        arr_test_ref_range:arrtestrefrange,
        // 3-mar-2025
        // arr_test_paragraph:arrtestparagraph,
        arr_test_paragraph:testParametersFull.layout_type=="ParaGraPH"? valuesArray:arrtemforHsistoResult,
        arr_previous_1:arrprevious1,
        arr_previous_2:arrprevious2,
        // 14-dec-2024 add one line to diffrence its save or save&feed
        // 24-feb-2025 histo report
        // comment for temporary jab tak histo k bn ni jata 2-mar-2025 (2/2) arrtestparagragh oper b hai us ko comment kr den ge 
        // arr_test_paragraph:testParametersFull.layout_type=="ParaGraPH"? valuesArray:arrtemforHsistoResult
      
      }
      console.log("arrpostiveNegative "+JSON.stringify(arrpostiveNegative))

       console.log("checj obj "+JSON.stringify(obj))

      //  alert("Successfully done ")


       fetch("https://reports.mpl-labs.pk:8443/4DACTION/WebTestsResultsSubmissionWS_api", {
          method: "POST",
          body: JSON.stringify(obj)
      }).then((res) => res.json()).then((response) => {
        console.log(response)
        if(response.transaction_status=="Successful"){
          // ye abi add kiya box bnd ho jae
     

          // alert("Save Records Successfully done ! "+JSON.stringify(response))
      setifLateCheckBox(false)
      // 3-mar-2025
          // setTimeout(() => {
          //   window.location.reload();
          // }, 1000);
   
          // setopenSecondPageBox(false)
            //  3-mar-2025 filtered kr k us ko actual array se remove kr dya save me zrorrat ni remove ki
            
      // console.log(selectedTestAuxId)
      // const filteredData = CallingUpTestsList.filter(item => item.aux_id !== selectedTestAuxId);
      // console.log(filteredData)
      //     setCallingUpTestsList(filteredData)
      //     setCallingUpTestsListForSearchIfLate(filteredData)   
          setselectedRowDetails()
          settestParametersFull([])
          setselectedTestAuxId()
          setselectedTestName("Tests Result Feeding")
          setifAutoLims() 
          setName("")
          setComments([])
          setprevResultCheckBox(false)
          setLookup({});
          setopenSecondPageBox(false)
          setloadingResultSave(false)

          

        }
        else{
          alert("Save Records Failure Tray Again ! "+response.transaction_status)
          setTimeout(() => {
            window.location.reload();
          }, 1000);  
        }
      })
      
        

    }else{
      alert("Please Select Branch !")
      setloadingResultSave(false)
    }
            
           }}
   
           // disabled={saveInvoice}
           style={{
            justifyContent: "flex-start", // Align content to the start
            textAlign: "left",           // Align text to the left
            //  width: "1.5em",
            //  padding: "2.5em",
            width:"13em",
            height:"2.5em",
          fontSize:13,
  
            // paddingTop:"12%",paddingBottom:"12%",
            //  height: "3.8em",
            //  marginLeft: "5%",
            //  marginTop:"20%",
            //  marginBottom:10
            backgroundColor:"#196c91"
            //  marginBottom:10
           }}>
                        {loadinResultSave?<ReactLoading type={'spinningBubbles'} color={'white'} height={'2em'} width={'2em'} />:
                            "Save Records"}
           </Button>
           </div>
           </GridItem>
           <GridItem md={12} lg={12} style={{display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"space-between",marginTop:"5%"}}>
           <div style={{width:"100%",
   marginLeft:-20

       }}>
           <Button
           variant="contained"
           color="primary"
           onClick={() => {
 
if(localStorage.getItem('branch_name')){

        // 3-mar-2025
        setloadinResultSaveUpdate(true)

  var arrparameterid = []
  var arrgrouptitle = []
  var arrparametertitle = []
  var arrapplygender = []
  var arrapplyage = []
  var arrresultvalue = []
  var arrresultstatus = []
  var arrtestunit = []
  var arrtestrefrange = []
  var arrtestparagraph = []
  var arrprevious1 = []
  var arrprevious2 = []
  // 27-nov-2024 
  var arrpostiveNegative = []
  // 24-feb-2025 histo reports agr histo k ilawa ho to ye array fill ho jae wo wali na ho ku k wo agr param 2 hn to us me 6 he rhy ge thats why
var arrtemforHsistoResult=[]
  
        var obj ={}
     var data= testParameter?.map((item,index)=>{
  console.log("value in testParameter is "+JSON.stringify(item))
  arrparameterid.push(item.aux_id)
  arrgrouptitle.push("")
  
  arrparametertitle.push(item.parameter_title)
  arrapplygender.push(item.apply_gender)
  arrapplyage.push(item.apply_age)
  // arrresultvalue.push(item.result_value)
arrresultvalue.push(item.result_value=="-"?"":item.result_value)

  arrresultstatus.push(item.result_status)
  arrtestunit.push(item.test_units)
  arrtestrefrange.push(item.ref_range)
  arrtestparagraph.push(item.test_paragraph)
  arrprevious1.push(item.previous_result_1)
  arrprevious2.push(item.previous_result_2)
  // 27-nov-2024 
  // 2-jan-2025 prev save value show h=ni ho rhi thi thats why cateogory ko skip kr in table column then change kr dya 
  // arrpostiveNegative.push(item.category?item.category:"")
arrpostiveNegative.push(item.result_status?item.result_status:"")
arrtemforHsistoResult.push("")

  
// 3-mar-2025 es ko cmment kiya ye crash krwa rha tha while saving data
// delete item.tableData
// return item
        })
        console.log({
         
          
        })
         //28-jan-2025 empty result cannot b submitted
      const areAllResultValueEmpty = arrresultvalue.every(value => value === '');
      const areAllResultPosNegEmpty = arrpostiveNegative.every(value => value === '');
      if(testParametersFull.layout_type!="ParaGraPH"){
      if(areAllResultValueEmpty && areAllResultPosNegEmpty ){
        alert("Result is empty & cannot be saved !")
                   window.location.reload();
return
      }}
         // 24-feb-2025 histo reports
         const valuesArray = Object.values(formData);
         if(testParametersFull.layout_type=="ParaGraPH"){
          if (Object.values(formData).every((value) => value.trim() !== "")) {
            // setSubmittedData([...submittedData, formData]); // Add data to the array
            setFormData({  // Reset form fields
              histoNumber: "",
              specimenType: "",
              clinicalInfo: "",
              grossExam: "",
              microscopicExam: "",
              opinions: "",
            });
            console.log(valuesArray)
            // alert("Data submitted successfully!");
          } else {
            alert("Please fill in all fields before submitting.");
            setloadinResultSaveUpdate(false)

          }
        }
        // console.log(arrparameterid)
  
        // console.log("value delete change"+JSON.stringify(testParameter))
        testParametersFull.parameters_obj=testParameter
        var obj = {
          aux_id:selectedTestAuxId,
        remarks_tests:remarksInternal,
        comments_tests:comments.toString(),
        if_updated:true,
        if_auto_LIMS:ifAutoLims,
        user_id:localStorage.getItem('user_id'),
        user_name:localStorage.getItem('name'),
        PDF_String: ScanPDF.includes("base64,") ? ScanPDF.split('base64,')[1] : ScanPDF,
        PDF_Name: ScanPDF != "" ? PDFDetails.name+".pdf" : "",
        Image_String:ScanImage != "" ? ScanImage.split('base64,')[1]:"",
        Image_Name: ScanImage != "" ? ImageDetails.name + ".png" : "",
        arr_paramter_id:arrparameterid,
        arr_group_title:arrgrouptitle,
        arr_paramter_title:arrparametertitle,
        arr_apply_gender:arrapplygender,
        arr_apply_age:arrapplyage,
        arr_result_value:arrresultvalue,
        // arr_result_status:arrresultstatus,
        arr_result_status:arrpostiveNegative,
        arr_test_unit:arrtestunit,
        arr_test_ref_range:arrtestrefrange,
        // arr_test_paragraph:arrtestparagraph,
        // 3-mar-2025
        // arr_test_paragraph:arrtestparagraph,
        arr_test_paragraph:testParametersFull.layout_type=="ParaGraPH"? valuesArray:arrtemforHsistoResult,
        arr_previous_1:arrprevious1,
        arr_previous_2:arrprevious2
        // 14-dec-2024 add one line to diffrence its save or save&feed
        
        }
        console.log("arrpostiveNegative "+JSON.stringify(arrpostiveNegative))
  
         console.log("checj obj "+JSON.stringify(obj))
         
       
        fetch("https://reports.mpl-labs.pk:8443/4DACTION/WebTestsResultsSubmissionWS_api", {
          method: "POST",
          body: JSON.stringify(obj)
      }).then((res) => res.json()).then((response) => {
        console.log(response)
        if(response.transaction_status=="Successful"){
          // ye abi add kiya box bnd ho jae
      //  setopenSecondPageBox(false)

          // alert("Save & Update Successfully done "+JSON.stringify(response))
          // setifLateCheckBox(false)
          // setTimeout(() => {
          //   window.location.reload();
          // }, 1000);
          console.log(selectedTestAuxId)
          const filteredData = CallingUpTestsList.filter(item => item.aux_id !== selectedTestAuxId);
          console.log(filteredData)
              setCallingUpTestsList(filteredData)
              setCallingUpTestsListForSearchIfLate(filteredData)   
              setselectedRowDetails()
              settestParametersFull([])
              setselectedTestAuxId()
              setselectedTestName("Tests Result Feeding")
              setifAutoLims() 
              setName("")
              setComments([])
              setprevResultCheckBox(false)
              setLookup({});
              setopenSecondPageBox(false)
              setloadinResultSaveUpdate(false)

         
          

        }
        else{
      //  setopenSecondPageBox(false)

          alert("Save & Update Failure Tray Again ! "+response.transaction_status)
          setTimeout(() => {
            window.location.reload();
          }, 1000);
         

        }
      })
     
      }else{
        alert("Please Select Branch !")
        setloadinResultSaveUpdate(false)

      }
            
           }}
   
           // disabled={saveInvoice}
           style={{
            textAlign: 'center',
          //  width: "1.5em",
          //  padding: "2.5em",
          width:"13em",
          height:"4em",
        fontSize:13,

          // paddingTop:"12%",paddingBottom:"12%",
          //  height: "3.8em",
          //  marginLeft: "5%",
          //  marginTop:"20%",
          //  marginBottom:10
          backgroundColor:"#f2105b"
            //  marginBottom:10
           }}>
             {loadinResultSaveUpdate?<ReactLoading type={'spinningBubbles'} color={'white'} height={'2em'} width={'2em'} />:
                            "Save & Update Feed"}    
           </Button>
           </div>
                 </GridItem>
                    
  </GridItem>
  </GridContainer>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
        <Button
            variant="contained"
            color="primary"
            // className={classes.button}
            style={{ 
              width:"8%",
              maxWidth:"8%",
              height: "3em",
   maxHeight:"3em",  
              // width:"10%",
              color:"white",
                            //  textAlign: 'center',
                            //  width: "1.5em",
                            //  padding: "2em",
                            //  paddingLeft:"3em",
                            //  paddingRight:"3em",
                            // paddingTop:"13%",paddingBottom:"13%",
                            //  height: "3em",
                             marginLeft: "1.5%",
                            //  marginTop:25,
                             backgroundColor:"#196c91",
                            fontSize:11}}
                     
                          simple
            >
 <FormControlLabel
              control={<Checkbox  checked={prevResultCheckBox} 
              onChange={(event)=>{
                setprevResultCheckBox(event.target.checked)
              }} name="Prev Result" />}
              // label="Prev Result"
              label={ <Typography style={{ fontSize: 11,fontWeight:"bold" }}>
             Prev Result
            </Typography>}
            />
            
          
            </Button>
       
          <Button
           startIcon={ <ReplyTwoToneIcon fontSize={"large"} style={{color:"white",}} />}
            onClick={() => {
              setopenSecondPageBox(false)
              setselectedRowDetails()
              // setImageDetails()
              // setScanImage()
              // setPDFDetails()
              // setScanPDF()
              // alert(" ki hoya")
            }}
            style={{ 
              width:"8%",
              maxWidth:"8%",
              height: "3em",
   maxHeight:"3em",
                // width:"10%",
color:"white",
              //  textAlign: 'center',
              //  width: "1.5em",
              //  padding: "2em",
              //  paddingLeft:"3em",
              //  paddingRight:"3em",
              // paddingTop:"13%",paddingBottom:"13%",
              //  height: "3em",
               marginLeft: "1.5%",
              //  marginTop:25,
               backgroundColor:"#196c91",
               fontSize:11}}
            color="danger"
            simple
          >
            Prev Tab
                    </Button>
        </DialogActions>
      </Dialog>
            {/* comment box open */}
            <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal
        }}

        maxWidth={'sm'}
        fullWidth={true}
        open={openCommentDialodBox}
        TransitionComponent={Transition}
        keepMounted

        onClose={() => {
      setopenCommentDialodBox(false)
        }}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >

          <h4 className={classes.modalTitle}><b>Select Comments</b></h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
              <div className={classes.paper}>
        <MaterialTable
            columns={[

              { title: "Code", field: "comment_code",width: "10%"  },
              { title: "Text", field: "comment_text",width: "70%"  },
              { title: "Select", field: "select",width: "20%"  },

             
            ]}
            data={allComments}
            title={"Comments"}
          />
        </div>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>

          <Button
            onClick={() => {
              setopenCommentDialodBox(false)

              
            }}
            color="danger"
            simple
          >
            Close
                    </Button>
        </DialogActions>
      </Dialog>

                  {/* Reject to phelobotomy */}
                  <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal
        }}

        maxWidth={'sm'}
        fullWidth={true}
        open={openRejectToPhelDialodBox}
        TransitionComponent={Transition}
        keepMounted

        onClose={() => {
      setopenRejectToPhelDialodBox(false)
        }}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        {/* <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >

          <h4 className={classes.modalTitle}><b>Reject To phelobotomy</b></h4>
        </DialogTitle> */}
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
          style={{marginTop:"5%"}}
        >
              <GridItem md={12} lg={12}>


                  <span style={{
    display: 'flex',
    flexDirection: "column",
   //  marginTop: 6,
   //  marginLeft:25
  }}>
    <b style={{}}>Rejection Remarks </b>
    <TextareaAutosize
      rowsMax={10}
      style={{
        padding: '0.5em',
        height: '3em',
        marginLeft: 1
      }}
      // value={comments}
      value={rejectionRemarks}
      onChange={(e) => {
        setRejectionRemarks(e.target.value)
      }}
      placeholder="Remarks for Rejection"
    />
    </span>
  
    </GridItem>
    <GridItem md={12} lg={12} >

   
    <Button
        variant="contained"
        color="primary"
        onClick={() => {
   //  alert("hello")
         //  SeveRecordHandler()
        //  setopenCommentDialodBox(true)
        console.log( selectedTestAuxId,
          rejectionRemarks,
         localStorage.getItem('user_id'),
          localStorage.getItem('name'),
        )
   //  setopenSecondPageBox(false)
   var obj={
    aux_id:selectedTestAuxId,
    remarks_rejection:rejectionRemarks,
    user_id:localStorage.getItem('user_id'),
    user_name:localStorage.getItem('name')
   }
   fetch("https://reports.mpl-labs.pk:8443/4DACTION/RF_TestRejectsToPhlebotomy_api", {
    method: "POST",
    body: JSON.stringify(obj)
}).then((res) => res.json()).then((response) => {
  console.log(response)
  // if(response.transaction_status=="Successful"){
    // ye abi add kiya box bnd ho jae
 setopenSecondPageBox(false)

    alert("Rejection Successfully done ! "+JSON.stringify(response))
    window.location.reload();
    



//   }
})

         
        }}

        // disabled={saveInvoice}
        style={{
          textAlign: 'center',
          alignSelf:"center",
          // width: "1.5em",
         //  padding: "2.5em",
         width:"80%",
        //  paddingTop:"6%",paddingBottom:"6%",
         //  height: "3.8em",
          marginLeft: "10%",
          marginTop:"4%",
          fontSize:12,
         //  marginBottom:10
         backgroundColor:"#196c91"
        }}>
          Reject to phlebotomy 
        </Button>
     
    </GridItem>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>

          <Button
            onClick={() => {
              setopenRejectToPhelDialodBox(false)
            }}
            color="danger"
            simple
          >
            Close
                    </Button>
        </DialogActions>
      </Dialog>

 {/* Document View  */}
 <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal
        }}
        maxWidth={false}
        fullWidth={true}
        open={openDocumentViewDialodBox}
        TransitionComponent={Transition}
        keepMounted
        sx={{ '& .MuiDialog-paper': { width: '80vw', maxWidth: '80vw', margin: 0 } }}

        PaperProps={{
          style: {
            width: '73.8vw',        // Full viewport width
            maxWidth: '74vw',     // No max width
            height: '94.8vh',       // Full viewport height
            maxHeight: '95vh',  
            marginLeft:"28vw"              // Remove margin to ensure full width
          }
        }}
      

        onClose={() => {
      setopenDocumentViewDialodBox(false)
        }}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        {/* <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >

          <h4 className={classes.modalTitle}><b>Reject To phelobotomy</b></h4>
        </DialogTitle> */}
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
          style={{marginTop:"5%"}}
        >
             <GridContainer>
                     
                     <GridItem xs={12} sm={12} md={6} lg={6} >
                     <MaterialTable
                      options={{
                        selection: false,
                        headerStyle: {
                          backgroundColor:"#196c91",
                          color: "#FFF",
                          fontSize: 13,
                          position: "sticky", // Make the header sticky
                      top: 0, // Stick the header to the top
                          // textAlign: "center",
                          // fontWeight: "bold",
                          // minHeight:"50em"
                        },
                        maxBodyHeight: "1000px",
                        pageSize: 100,
                        fixedHeader: true,
                        actionsColumnIndex: -1,
                
                        rowStyle: rowData => {
                          
                          return {
                          fontSize: 13,
                          
                        };
                        } ,
                
                      }}
                     style={{marginLeft:25}}
                     
   title="PDF Files"
   data={rows}
   columns={[  
     { title: 'ID', field: 'dms_id' },
     { title: 'Tag ', field: 'dms_tag' },
     {title: 'Title', field:"dms_title"},
     { title: 'Date', field: 'dms_date' },
     { title: 'View', field: 'view' }

   ]}
   // maxBodyHeight={"50em"}
  //  options={{
  //    // selection: true,
  //    paging:true,
  //    minBodyHeight:40,
  //    // showSelectAllCheckbox:SelectionControl
  //  }}
 />
      </GridItem>
      <GridItem xs={12} sm={12} md={6} lg={6} >
                     <MaterialTable
                      options={{
                        selection: false,
                        headerStyle: {
                          backgroundColor:"#196c91",
                          color: "#FFF",
                          fontSize: 13,
                          position: "sticky", // Make the header sticky
                      top: 0, // Stick the header to the top
                          // textAlign: "center",
                          // fontWeight: "bold",
                          // minHeight:"50em"
                        },
                        maxBodyHeight: "1000px",
                        pageSize: 100,
                        fixedHeader: true,
                        actionsColumnIndex: -1,
                
                        rowStyle: rowData => {
                          
                          return {
                          fontSize: 13,
                          
                        };
                        } ,
                
                      }}
                     style={{marginRight:25}}
   title="Image Scanned"
   data={rowsImg}
   columns={[  
     { title: 'ID', field: 'dms_id' },
     { title: 'Tag ', field: 'dms_tag' },
     {title: 'Title', field:"dms_title"},
     { title: 'Date', field: 'dms_date' },
     { title: 'View', field: 'view' }
   ]}
   // maxBodyHeight={"50em"}
  //  options={{
  //    // selection: true,
  //    paging:true,
  //    minBodyHeight:40,
  //    // showSelectAllCheckbox:SelectionControl
  //  }}
 />
      </GridItem>
                     
                   

                   </GridContainer>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>

          <Button
            onClick={() => {
              setopenDocumentViewDialodBox(false)
            }}
            color="danger"
            simple
          >
            Close
                    </Button>
        </DialogActions>
      </Dialog>
{/* ////////////////////// Pdf View Dialog box/////////////////////////// */}
{selectInvoice && 
  <Dialog
  classes={{
    root: classes.center,
    paper: classes.modal
  }}
  sx={{ '& .MuiDialog-paper': { width: '80vw', maxWidth: '80vw', margin: 0 } }}

  PaperProps={{
    style: {
      width: '73.8vw',        // Full viewport width
      maxWidth: '74vw',     // No max width
      height: '94.8vh',       // Full viewport height
      maxHeight: '95vh',  
      marginLeft:"28vw"  ,            // Remove margin to ensure full width

    }
  }}

  maxWidth={'xl'}
  fullWidth={true}
  open={selectInvoice}
  TransitionComponent={Transition}
  keepMounted
BackdropProps={{
    style: { backgroundColor: 'transparent' }, // Set backdrop to transparent
  }}
  onClose={() => {
    setselectInvoice(false)
    // setSelectedReceipt({
    //   reports: []
    // })
  }}
  aria-labelledby="classic-modal-slide-title"
  aria-describedby="classic-modal-slide-description"
>
  {/* <DialogTitle
    id="classic-modal-slide-title"
    disableTypography
    className={classes.modalHeader}
  >

    <h4 className={classes.modalTitle}><b>PDF View</b></h4>
  </DialogTitle> */}
  <DialogContent
    id="classic-modal-slide-description"
    className={classes.modalBody}
  >
    <RefreshIcon fontSize="large" color="primary" style={{cursor:"pointer",alignSelf:"center",color:"#196c91",marginLeft:"95%"}} onClick={()=>{
          // alert("Refresh")
          setSelectedReportNotShowingCondition(true)
        }} >  </RefreshIcon>
     <center> <React.Fragment key={"sqq"} id>
          {/* <PDFViewer width="1000" height="1000" className="app" >
            <Invoice invoice={selectedInvoice} />
          </PDFViewer> */}
          {/* 09-oct-2023 height 400 ki jga 800 kr di */}
            {/* <iframe  src={selectedReport} width="100%" height={400}></iframe> */}
          
            {/* <iframe  src={selectedReport} width="100%" height={800}></iframe> */}
            {
            selectedReportNotShowingCondition?
            <iframe  src={`https://reports.mpl-labs.pk:8443/4DACTION/DMS_urlCallsPDF_api/${selectedReportNotShowing}`} width="100%" height={800}></iframe>
:
           <iframe  src={selectedReport} width="100%" height={800}></iframe> 

          }
            {/* <img  src={selectedReport} alt='test' ></img>      */}


        </React.Fragment>
        </center>

    
     
    {/* {
      selectedInvoice[0].sr != undefined && selectInvoice ?
        <center> <React.Fragment key={"sqq"} id>
          <PDFViewer width="1000" height="1000" className="app" >
            <Invoice invoice={selectedInvoice} />
          </PDFViewer>
        </React.Fragment>
        </center>
        : <></>
    } */}
  </DialogContent>
  <DialogActions className={classes.modalFooter}>

    <Button
      onClick={() => {
        setselectInvoice(false)
        // setSelectedReceipt({
        //   reports: []
        // })
      }}
      color="danger"
      simple
    >
      Close
              </Button>
  </DialogActions>
</Dialog>

 }
   {/* ////////////////////// Scanned Image View Dialog box/////////////////////////// */}
   {selectImage && 
  <Dialog
  classes={{
    root: classes.center,
    paper: classes.modal
  }}
  sx={{ '& .MuiDialog-paper': { width: '80vw', maxWidth: '80vw', margin: 0 } }}

  PaperProps={{
    style: {
      width: '73.8vw',        // Full viewport width
      maxWidth: '74vw',     // No max width
      height: '94.8vh',       // Full viewport height
      maxHeight: '95vh',  
      marginLeft:"28vw"  ,            // Remove margin to ensure full width

    }
  }}
  maxWidth={'xl'}
  fullWidth={true}
  open={selectImage}
  TransitionComponent={Transition}
  keepMounted

  onClose={() => {
    setselectImage(false)
    // setSelectedReceipt({
    //   reports: []
    // })
  }}
  aria-labelledby="classic-modal-slide-title"
  aria-describedby="classic-modal-slide-description"
>
  <DialogTitle
    id="classic-modal-slide-title"
    disableTypography
    className={classes.modalHeader}
  >

    <h4 className={classes.modalTitle}><b>Image View</b></h4>
  </DialogTitle>
  <DialogContent
    id="classic-modal-slide-description"
    className={classes.modalBody}
  >
     <center> <React.Fragment key={"sqq"} id>
          {/* <PDFViewer width="1000" height="1000" className="app" >
            <Invoice invoice={selectedInvoice} />
          </PDFViewer> */}
            {/* <iframe  src={selectedReport} width="100%" height={400}></iframe> */}
            <img  src={selectedReport} alt='test' ></img>     


        </React.Fragment>
        </center>

    
     
    {/* {
      selectedInvoice[0].sr != undefined && selectInvoice ?
        <center> <React.Fragment key={"sqq"} id>
          <PDFViewer width="1000" height="1000" className="app" >
            <Invoice invoice={selectedInvoice} />
          </PDFViewer>
        </React.Fragment>
        </center>
        : <></>
    } */}
  </DialogContent>
  <DialogActions className={classes.modalFooter}>

    <Button
      onClick={() => {
        setselectImage(false)
        // setSelectedReceipt({
        //   reports: []
        // })
      }}
      color="danger"
      simple
    >
      Close
              </Button>
  </DialogActions>
</Dialog>

 }
  
        </>
    );
}
